import React, { useState } from "react";

import { Tabs, Tab, Row, Col } from "react-bootstrap";
import Summary from "./Summary";
import Trades from "./Trades";

const BotPerformance = (props) => {
  const { baseCoin, quoteCoin, strategy,t } = props;
  const lowerCaseStrategy = (strategy && strategy.toLowerCase()) || "long";
  const [activeTab, setActiveTab] = useState(0);
  const tabTitles = [t("summary"), t("trades")];
  const tabHeaders = tabTitles.map((item, index) => {
    return (
      <a
          onClick={() => setActiveTab(index)}
          key={index}
          className={index == activeTab ? "list-tab tab-active" : "list-tab tab-inactive"}
        >
          {item}
        </a>
    );
  });
  const tabs = [
    <Summary baseCoin={ quoteCoin} t={t}/>,
    <Trades
      baseCoin={quoteCoin || "USDT"}
      quoteCoin={baseCoin || "BTC"}
      isLong={lowerCaseStrategy == "long"}
      t={t}
    />,
  ];
  return (
    <Col xl={12} xs={12} className="backtest-param-div mt-4">
      <Col xl={12} className="section-tabs-bar">{tabHeaders}</Col>
      <div className="card-white cb-section-1">
          <div className="cw-body bp-div">
            {tabs[activeTab]}
            </div>
      </div>
    </Col>
  );
};

export default BotPerformance;
