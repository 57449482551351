import { PAYPAL_ID, STRIPE_ID } from "../config";
import { SUB_METHOD, SUB_STATUS, SUB_TIER } from "../Const";
import { dayTillNow, formatDate } from "./Utils";

export const getMonthlyPrice = (tier) => {
  switch (tier) {
    case SUB_TIER.premium:
      return "$13.99";
    case SUB_TIER.pro:
      return "$29.99";
    case SUB_TIER.master:
      return "$99.99";
    default:
      return "$13.99";
  }
};

export const getYearlyPrice = (tier) => {
  switch (tier) {
    case SUB_TIER.premium:
      return "$139.99";
    case SUB_TIER.pro:
      return "$299.99";
    case SUB_TIER.master:
      return "$999.99";
    default:
      return "$139.99";
  }
};

// export const getStripePlan = (plan, tier) => {
//   switch (tier) {
//     case SUB_TIER.premium:
//       return monthly ? STRIPE_ID.premium_monthly : STRIPE_ID.premium_yearly;
//     case SUB_TIER.pro:
//       return monthly ? STRIPE_ID.pro_monthly : STRIPE_ID.pro_yearly;
//     default:
//       return STRIPE_ID.pro_monthly;
//   }
// };
export const getStripePlan = (plan, tier) => {
  switch (tier) {
    case SUB_TIER.premium:
      switch(plan){
        case "monthly":
          return STRIPE_ID.premium_monthly
        case "quarterly":
          return STRIPE_ID.premium_quarterly
        case "yearly":
          return STRIPE_ID.premium_yearly
      }
    case SUB_TIER.pro:
      switch(plan){
        case "monthly":
          return STRIPE_ID.pro_monthly
        case "quarterly":
          return STRIPE_ID.pro_quarterly
        case "yearly":
          return STRIPE_ID.pro_yearly
      }
    case SUB_TIER.master:
      switch(plan){
        case "monthly":
          return STRIPE_ID.master_monthly
        case "quarterly":
          return STRIPE_ID.master_quarterly
        case "yearly":
          return STRIPE_ID.master_yearly
      }
    default:
      return STRIPE_ID.pro_monthly;
  }
};

export const getPaypalPlan = (plan, tier) => {
  switch (tier) {
    case SUB_TIER.premium:
      switch(plan){
        case "monthly":
          return PAYPAL_ID.premium_monthly
        case "quarterly":
          return PAYPAL_ID.premium_quarterly
        case "yearly":
          return PAYPAL_ID.premium_yearly
      }
    case SUB_TIER.pro:
      switch(plan){
        case "monthly":
          return PAYPAL_ID.pro_monthly
        case "quarterly":
          return PAYPAL_ID.pro_quarterly
        case "yearly":
          return PAYPAL_ID.pro_yearly
      }
    case SUB_TIER.pro:
      switch(plan){
        case "monthly":
          return PAYPAL_ID.master_monthly
        case "quarterly":
          return PAYPAL_ID.master_quarterly
        case "yearly":
          return PAYPAL_ID.master_yearly
      }
    default:
      return PAYPAL_ID.pro_monthly;
  }
};

export const getDayRemain = (end_date) => {
  const days = -dayTillNow(end_date).time.toFixed(0);
  return days > 0 ? days : "0";
};

export const checkSub = (sub) => {
  let subMethod,
    subType,
    subPremium = false,
    subStatus = SUB_STATUS.active,
    subEndDate = "-",
    subInvoices = [],
    subDayLeft = "-",
    subTier = SUB_TIER.free;
  if (!sub) return { subPremium, subInvoices, subTier };
  const {
    end_date,
    stripe_subscription_status,
    stripe_invoices,
    apple_invoices,
    paypal_invoices,
    tier,
    payment_method,
    period,
  } = sub;
  subTier = tier;
  subMethod = payment_method;
  subType = period;
  subInvoices =
    (subMethod == SUB_METHOD.stripe
      ? adaptStripeInvoice(stripe_invoices)
      : subMethod == SUB_METHOD.apple
      ? adaptAppleInvoice(apple_invoices)
      : subMethod == SUB_METHOD.paypal
      ? adaptPaypalInvoice(paypal_invoices)
      : []) || [];
  subEndDate = formatDate(end_date);
  subDayLeft = getDayRemain(end_date);
  subPremium = dayTillNow(end_date).time < 0;

  switch (subMethod) {
    case SUB_METHOD.stripe:
      subStatus = ["canceled", "cancel_at_period_end"].includes(
        stripe_subscription_status
      )
        ? SUB_STATUS.canceled
        : SUB_STATUS.active;
      break;
    case SUB_METHOD.paypal:
      break;
    default:
      break;
  }

  return {
    subMethod,
    subType,
    subPremium,
    subStatus,
    subEndDate,
    subInvoices,
    subDayLeft,
    subTier,
  };
};

export const isPremium = (sub) => {
  if (!sub) return false;
  const { end_date } = sub;
  if (!end_date) return false;
  return dayTillNow(end_date).time < 0;
};
export const isPro = (subscription) => {
  const { subPremium, subTier } = checkSub(subscription);
  return subPremium && subTier === SUB_TIER.pro
};
export const isMaster = (subscription) => {
  const { subPremium, subTier } = checkSub(subscription);
  return subPremium && subTier === SUB_TIER.master
};

const adaptStripeInvoice = (invoices) => {
  return (
    (invoices &&
      invoices.length &&
      invoices.filter((el)=>el.invoice_status=="paid")
      .map((item) => ({
        time: item.created,
        item: item.item,
        txId: item.invoice_id,
        amount: "$" + item.amount_paid / 100,
      }))) ||
    []
  );
};

const adaptAppleInvoice = (invoices) => {
  return (
    (invoices &&
      invoices.length &&
      invoices.map((item) => ({
        time: item.purchase_date,
        item: item.item,
        txId: item.transaction_id,
        amount: "$" + item.amount_paid,
      }))) ||
    []
  );
};

const adaptPaypalInvoice = (invoices) => {
  return (
    (invoices &&
      invoices.length &&
      invoices.map((item) => ({
        time: item.time,
        item: item.item,
        txId: item.tnx_id,
        amount: "$" + item.gross_amount,
      }))) ||
    []
  );
};
