import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { getBalance, getPairBalance } from "../../actions/bot-action";
import { Modal, Row, Col, Button, Accordion, Form } from "react-bootstrap";
import TVChartContainer from "./TVChartContainer";
import "./CreateBotModal.scss";
import AssetView from "./new-bot/AssetView";
import TradeParamsView from "./new-bot/TradeParamsView";
import EntryConditionsView from "./new-bot/EntryConditionsView";
import ExitConditionsView from "./new-bot/ExitConditionsView";
import BotPerformance from "./new-bot/backtest/BotPerformance";
import { getTvTimeframe } from "../../utils/TvUtils";
import { TimeWindow, CreateBotModalType, BotType, SUB_TIER } from "../../Const";
import IndicatorSetting from "./new-bot/indicator-setting/IndicatorSetting";
import {
  CHANGE_BACKTEST_CUSTOM,
  CHANGE_TIME_FRAME,
  CHANGE_TRASACTION_FEE,
  SET_APPLY_CURRENT_DEAL,
  SET_BOT_NAME,
} from "../../actions/create-bot-type";
import { toast } from "react-toastify";
import Loading from "../common/Loading";
import useLoading from "../hooks/useLoading";
import {
  runBackTest,
  resetBotConfig,
  updateBot,
} from "../../actions/create-bot-action";
import { GET_BACKTEST_SUCCESS } from "../../actions/types";
import DcaEntryView from "./new-bot/dca-bot/DcaEntryView";
import DcaExitView from "./new-bot/dca-bot/DcaExitView";
import PriceExitView from "./new-bot/price-bot/PriceExitView"
import { checkSub, isMaster, isPremium, isPro } from "../../utils/SubUtils";
import { restrictAlphabets } from "../../utils/Utils";
import GridBotParamsView from "./new-bot/grid-bot/GridBotParamsView";
import GridExitView from "./new-bot/grid-bot/GridExitView";
import ProjectTour from "../tour/ProjectTour";
import { Trans } from "react-i18next";
import SellBotExitView from "./new-bot/sell-bot/SellBotExitView";
import AssetViewFutures from "./new-bot/AssetViewFutures";
import FuturesTradeParamsView from "./new-bot/FuturesTradeParamsView";
import CustomBackTest from "./new-bot/backtest/CustomBackTest";
import AssetViewInterArbitrage from "./new-bot/inter-arbitrage-bot/AssetViewInterArbitrage";
import AssetViewIntraArbitrage from "./new-bot/intra-arbitrage-bot/AssetViewIntraArbitrage";

const Frequencies = {
  15: "15",
  60: "60",
  120: "120",
  240: "240",
  1440: "1D",
};
const FrequenciesPro = {
  5: "5",
  15: "15",
  60: "60",
  120: "120",
  240: "240",
  1440: "1D",
};



const TimeFrame_1D = ["1D", "1W", "1M", "3M", "6M", "1Y"];
const TimeFrame_1H = ["1D", "1W", "1M", "2M", "3M", "6M"];
const TimeFrame_15m = ["1D", "1W", "2W", "4W", "5W", "6W"];
const proTImeframe = ["1D", "1W", "1M", "3M", "6M", "1Y"];

const CreateBotModal = (props) => {
  const {
    modalType,
    onHide,
    getBalance,
    getPairBalance,
    runBackTest,
    resetBotConfig,
    updateBot,
    itemsPerPage,
    pageNum,
    selectedTab,
    selectedExchangeId ,
    t
  } = props;

  const [setLoading, bindLoading] = useLoading(false);
  const [timeFrames, setTimeFrames] = useState(TimeFrame_1D);
  const [interval, setInterval] = useState(timeFrames[timeFrames.length - 1]);
  const [isCustomInterval, setIsCustomInterval] = useState(false);
  const [editName, setEditName] = useState(false);
  const [customDate, setCustomDate] = useState(false);

  const reloadTV = useSelector((state) => state.bots.reloadTV);
  const isFuture = useSelector((state) => state.settings.isFuture);
  const defaultIndicators = useSelector((state) => state.bots.indicators) || [];
  const newBot = useSelector((state) => state.createBot.newBot);
  const editingIndicator = useSelector(
    (state) => state.createBot.editingIndicator
  );
  const exchangeId = newBot.exchange.id;
  const frequency = newBot.frequency;
  const baseCoin = newBot.baseCoin;
  const quoteCoin = newBot.quoteCoin;
  const strategy = newBot.strategy;
  const pair = newBot.pair;
  const botName = newBot.name;
  const editing  = modalType === CreateBotModalType.editBot || true;
  const arbitrageBot = (newBot.botType==BotType.inter_arbitrage || newBot.botType==BotType.intra_arbitrage);
  const dispatch = useDispatch();
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  const { subPremium, subTier } = checkSub(subscription);

  const changeTimeFrame = (val) => {
    dispatch({
      type: CHANGE_TIME_FRAME,
      payload: val,
    });
  };
  const changeTransactionFee = (val) => {
    dispatch({
      type: CHANGE_TRASACTION_FEE,
      payload: val,
    });
  };
  const setCustomBacktest = (val) => {
    dispatch({
      type: CHANGE_BACKTEST_CUSTOM,
      payload: val,
    });
  };
  useEffect(() => {
    changeTimeFrame(TimeWindow[interval]);
  }, [interval]);
  const changeBacktestTimeframe = (val) => {
    setIsCustomInterval(true)
    if(frequency <=15 && val > 180){
      changeTimeFrame(180);
      return false;
    }
    changeTimeFrame(val);
  };
  const clickInterval = (val) => {
    setIsCustomInterval(false)
    setInterval(val);
  };
  useEffect(() => {
    window.bot = { strategy, orders: [] };
    return () => {
      resetBotConfig();
      dispatch({
        type: GET_BACKTEST_SUCCESS,
        payload: null,
      });
    };
  }, []);

  const setApplyCurrentDeal = (val) => {
    dispatch({
      type: SET_APPLY_CURRENT_DEAL,
      payload: val,
    });
  };

  const setBotName = (val) => {
    dispatch({
      type: SET_BOT_NAME,
      payload: val,
    });
  };

  const clickCreateBot = () => {
    setLoading(true);
    updateBot(itemsPerPage,1,selectedTab,selectedExchangeId)
      .then(() => {
        setLoading(false);
        toast.success(t("bot-created-msg"));
        onHide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(t(err));
      });
  };

  const clickUpdateBot = () => {
    setLoading(true);
    updateBot(itemsPerPage,pageNum,selectedTab,selectedExchangeId)
      .then(() => {
        setLoading(false);
        toast.success(t("bot-updated-msg"));
        onHide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(t(err));
      });
  };

  const clickBackTest = () => {
    setLoading(true);
    runBackTest()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(t(err));
      });
  };
  useEffect(() => {
    switch (frequency) {
      case 15:
        let timeframeFreq = subPremium?proTImeframe:TimeFrame_15m;
        setTimeFrames(timeframeFreq);
        setInterval(timeframeFreq[timeframeFreq.length - 1]);
        break;
      case 60:
        let timeframeFreq1 = subPremium?proTImeframe:TimeFrame_1H;
        setTimeFrames(timeframeFreq1);
        setInterval(timeframeFreq1[timeframeFreq1.length - 1]);
        break;
      default:
        setTimeFrames(TimeFrame_1D);
        setInterval(TimeFrame_1D[TimeFrame_1D.length - 1]);
    }
  }, [frequency]);

  useEffect(() => {
    getBalance(exchangeId);
  }, [exchangeId]);
  useEffect(() => {
    //getPairBalance(exchangeId,pair.id,isFuture?'usd_m':'spot');
  }, [exchangeId]);
  
  const frequencyVal= (isPro(subscription) || isMaster(subscription))?FrequenciesPro:Frequencies;
  const timeFrameButtons = timeFrames.map((item, index) => {
    const buttonClass = `w-100 ${
      (item == interval &&  !isCustomInterval)? "button-active" : "button-inactive"
    }`;
    return (
      <Col key={index}>
        <Button className={buttonClass} onClick={() => clickInterval(item)}>
          {item}
        </Button>
      </Col>
    );
  });
  const getExitView = ()=>{
    switch (newBot.botType) {
      case BotType.dca:
        return <DcaExitView newBot={newBot} t={t} />
      case BotType.price :
        return <PriceExitView newBot={newBot} t={t}/>
      case BotType.grid :
        return <GridExitView newBot={newBot} t={t}/>
      case BotType.sell :
        return <SellBotExitView newBot={newBot} t={t}/>
      default:
        return <ExitConditionsView newBot={newBot} t={t} editing={modalType === CreateBotModalType.editBot}/>
    }
  }
  const getAssetView = ()=>{
    switch (newBot.botType) {
      case BotType.inter_arbitrage:
        return <AssetViewInterArbitrage newBot={newBot} t={t} editing={modalType === CreateBotModalType.editBot} />
      case BotType.intra_arbitrage :
        return <AssetViewIntraArbitrage newBot={newBot} t={t} editing={modalType === CreateBotModalType.editBot}/>
      default:
        return <AssetView newBot={newBot} t={t} editing={modalType === CreateBotModalType.editBot}/>
    }
  }
  return (
    <>
      <Modal show={true} onHide={onHide} size="xl" dialogClassName="modal-90w">
        <Modal.Header closeButton className="align-items-center">
          <Modal.Title className="create-bot-name-tour">
            {!editName ? (
              botName
            ) : (
              <Form.Control
                value={botName}
                onChange={(e) => setBotName(e.target.value)}
              />
            )}{" "}
          </Modal.Title>
          <a className="ml-3" onClick={() => setEditName(!editName)}>
            <i className={editName ? "far fa-check-circle" : "far fa-edit"}></i>
          </a>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={7}>
              <Row style={{ height: "400px" }}>
                <TVChartContainer
                  pairId={pair.id}
                  pricePrecision={newBot.pricePrecision}
                  symbol={`${baseCoin}/${quoteCoin}`}
                  interval={frequencyVal[frequency]}
                  reload={reloadTV}
                  timeframe={getTvTimeframe(interval)}
                />
              </Row>
              <Row noGutters className="mt-4">
                <Col xs="9">
                  <Row noGutters>{timeFrameButtons}</Row>
                </Col>
                
                {(newBot.botType !=BotType.grid && newBot.botType !=BotType.sell && !arbitrageBot) && 
                <Col className="d-flex backtest-btn-bot" xs={12} lg={3}>
                  <Button variant="outline-success" onClick={clickBackTest} className="backtest-tour">
                    {t("run-backtest")}
                  </Button>
                </Col> }
              </Row>
              <Row noGutters className="mt-4 ml-4">
              {(isPremium(subscription) && (newBot.botType !=BotType.grid && newBot.botType !=BotType.sell && !arbitrageBot)) && 
                <>
                <Col xs="12">
                  <div className="d-flex">
                    <div><label className="mt-2">{t("backtest-from")} </label></div>
                    <div> <input
                    type="number"
                    value={newBot.timeFrame}
                    onChange={(e) => {
                      changeBacktestTimeframe(e.target.value);
                    }}
                    className="form-control"
                    onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}
                    style={{ marginRight: 10, marginLeft: 10, width: 100 }}
                  /></div>
                  <div><label className="mt-2">{t("backtest-days-ago")}</label></div>
                  </div>
                  
                </Col>
                <Col xs="12 mt-3">
                <div className="d-flex">
                  <div><label className="mt-2">{t("transaction-fee")} </label></div>
                  <div>
                    <input
                    type="number"
                    className="form-control"
                    value={newBot.transactionFee}
                    onChange={(e) => {
                      changeTransactionFee(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}
                    style={{ marginRight: 10, marginLeft: 10, width: 100 }}
                  />
                  </div>
                  <div>
                    <label className="mt-2">%</label>
                  </div>
                </div>
                
                
                </Col>
                </>
                }
                {(isPremium(subscription)  && !arbitrageBot) ?
                <>
                 <Form.Check
                  type="checkbox"
                  label={"Use Custom Date"}
                  onChange={(e) => setCustomBacktest(Boolean(e.target.checked))}
                  className={"w-100 mb-3"}
              /><br></br>
                {newBot.backtestCustom &&
                <CustomBackTest newBot={newBot}></CustomBackTest>
                }
                </>:
                <></>
                }
              </Row>
            </Col>
            <Col xl={5} className="mt-3 mt-xl-0">
              <Accordion defaultActiveKey="0">
                {isFuture? <AssetViewFutures newBot={newBot} t={t} editing={modalType === CreateBotModalType.editBot}/> : getAssetView()}
                {isFuture?<FuturesTradeParamsView  
                  editing={modalType === CreateBotModalType.editBot}
                  newBot={newBot}
                  t={t}></FuturesTradeParamsView>:<>
                {newBot.botType === BotType.grid ? (
                  <GridBotParamsView editing={modalType === CreateBotModalType.editBot}
                  newBot={newBot} t={t}/>
                ) : (
                  <TradeParamsView
                  editing={modalType === CreateBotModalType.editBot}
                  newBot={newBot}
                  t={t}
                />
                )}
                </>}
                
                {newBot.botType === BotType.dca ? (
                  <DcaEntryView newBot={newBot} t={t}/>
                ) : (
                  <EntryConditionsView newBot={newBot} t={t}/>
                )}
                {getExitView()}
              </Accordion>
            </Col>
          </Row>
          { !arbitrageBot &&
          <Row className="my-4">
            <Col>
              <BotPerformance
                baseCoin={baseCoin}
                quoteCoin={quoteCoin}
                strategy={strategy}
                t={t}
              />
            </Col>
          </Row> }
          <Row style={{ marginBottom: 30 }}>
            {modalType === CreateBotModalType.editBot ? (
              <div className="mx-auto align-items-center">
                <Button size="lg" variant="success" onClick={clickUpdateBot}>
                  {t("update-bot")}
                </Button>
                <Form.Check
                  value={newBot.applyCurrentDeal}
                  onChange={(e) => setApplyCurrentDeal(e.target.checked)}
                  label={t("apply-changes-curr-deal")}
                  className="ml-3"
                  inline
                />
              </div>
            ) : (
              <Button
                className="mx-auto create-bot-submit-tour mt-3"
                size="lg"
                variant="success"
                onClick={clickCreateBot}
              >
                {t("create-bot")}
              </Button>
            )}
          </Row>
        </Modal.Body>
      </Modal>
      {editingIndicator && (
        <IndicatorSetting
          isEntry={editingIndicator && editingIndicator.isEntry}
          indicator={editingIndicator && editingIndicator.indicator}
          strategy={strategy}
          defaultIndicators={defaultIndicators}
          baseCoin={baseCoin}
          newBot = {newBot}
        />
      )}
      <Loading {...bindLoading} />
    </>
  );
};

export default connect(null, {
  getBalance,
  getPairBalance,
  runBackTest,
  resetBotConfig,
  updateBot,
})(CreateBotModal);
