import React from "react";
import { Button } from "react-bootstrap";
import "./FreeInfo.scss";

const ProPlan = (props) => {
  const { isPremium,t } = props;
  return (
    <>
        <h6 className="text-row">
              <i className="fas fa-check item-check"></i>{" "}
              30 {t("active-bots")}
          </h6>
          <h6 className="text-row">
            <i className="fas fa-check item-check"></i> {t("grid-bot")}
          </h6>
          <h6 className="text-row">
            <i className="fas fa-check item-check"></i>{" "}
            {t("five-minutes-basic-plan")}
          </h6>
          <h6 className="text-row">
            <i className="fas fa-check item-check"></i>{" "}
            {t("custom-backtest-period")}
          </h6>
          <h6 className="text-row">
            <i className="fas fa-check item-check"></i>{" "}
            10 {t("price-alerts")}
          </h6>
          <h6 className="text-row">
            <i className="fas fa-check item-check"></i>{" "}
            {t("plus-premium-plan")}
          </h6>
    </>
  );
};

export default ProPlan;
