import './styles/theme.scss';
import "./App.scss";
import React from "react";
import { Provider, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { store, persistor } from "./store";
import Main from "./Main";
import Login from "./components/auth/Login";
import SignUp from "./components/auth/SignUp";
import ForgetPass from "./components/auth/ForgetPass";
import { PersistGate } from "redux-persist/integration/react";
import NewPass from "./components/auth/NewPass";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import VerifyOtp from "./components/auth/VerifyOtp";

import ReactGA from "react-ga";
import RouteChangeTracker from "./components/help/RouteChangeTracker";
import { ANALYTIC_ID, PAYPAL_CLIENT_ID } from "./config";
import SignUpSuccess from "./components/auth/SignUpSuccess";
import TokoSignIn from "./components/auth/TokoSignIn";
import TokoSignUp from "./components/auth/TokoSignUp";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
//import DiscoverPublic from "./components/discover/DiscoverPublic";
import Affiliate from "./components/affiliate/home/Affiliate";
import LeaderBoard from "./components/affiliate/leader-board/LeaderBoard";
import CjTracking from './components/cj/CjTracking';
import ProjectTour from './components/tour/ProjectTour';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
ReactGA.initialize(ANALYTIC_ID);

function App() {
  const initialOptions = {
    "client-id": PAYPAL_CLIENT_ID,
    currency: "USD",
    vault: true,
  };
  const cookies = new Cookies();
  const { i18n } = useTranslation();
  const lang = cookies.get("current_lang")
  i18n.changeLanguage(lang?lang:"en");
  return (
    <PayPalScriptProvider options={initialOptions}>
      <Router>
        <Provider store={store}>
        <CjTracking></CjTracking>
        {/* <LanguageHandler></LanguageHandler> */}
          <PersistGate loading={null} persistor={persistor}>
            <Switch>
              <Route exact path="/">
                <Redirect to="/login" />
              </Route>
              <Route exact path="/login">
                <Login></Login>
              </Route>
              <Route exact path="/signup">
                <SignUp></SignUp>
              </Route>
              <Route exact path="/forget-pass">
                <ForgetPass></ForgetPass>
              </Route>
              <Route exact path="/reset-pass">
                <NewPass />
              </Route>
              <Route exact path="/tokocrypto">
                <TokoSignIn />
              </Route>
              <Route exact path="/tokosignup">
                <TokoSignUp></TokoSignUp>
              </Route>
              <Route exact path="/verify-otp">
                <VerifyOtp />
              </Route>
              <Route exact path="/signup/success">
                <SignUpSuccess />
              </Route>
              {/* <Route exact path="/discover/public">
                <DiscoverPublic />
              </Route> */}
              <Route exact path="/affiliate">
                <Affiliate />
              </Route>
              <Route exact path="/leader-board">
                <LeaderBoard />
              </Route>
              <Main />
            </Switch>
          </PersistGate>
        </Provider>
        <ToastContainer position="bottom-right" autoClose={5000} />
        <RouteChangeTracker />
      </Router>
    </PayPalScriptProvider>
  );
}

export default App;
