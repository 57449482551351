import React from "react";
import ToolTip from "./ToolTip";

const TutorialPopup = (props) => {
  return (
    <ToolTip content={props.content}>
      <img src="/images/icons/icon-question-small.svg" width="15" height="15" alt="" className="ml-2"/>
    </ToolTip>
  );
};

export default TutorialPopup;
