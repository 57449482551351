import React from "react";
import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";
import { roundNumber } from "../../utils/Utils";
import { CryptoAssetColors } from "../../Const";

import "./DistributionPanel.scss";

const DistributionPanelFutures = (props) => {
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const { coins,t ,heading} = props;
  var sortCoins = coins;
  if (sortCoins == null) {
    return <></>;
  }
  sortCoins.sort((coin1, coin2) => coin2.holdings - coin1.holdings);
  var topCoins = sortCoins.slice(0, 5);
  const otherCoins = sortCoins.slice(5);
  if (otherCoins.length > 0) {
    const otherHolding = otherCoins.reduce(
      (acc, item) => acc + item.holdings,
      0
    );
    topCoins.push({
      symbol: "Others",
      holdings: otherHolding,
    });
  }

  const labels = topCoins && topCoins.map((item) => item.symbol);
  const holdings =
    topCoins && topCoins.map((item) => roundNumber(item.holdings));
  return (
    <div className="portfolio-pie text-center">
        <h5 className="text-center mb-4">{heading}</h5>
          <Pie data={data(holdings, labels,theme)} options={options(holdings)} />
    </div>
  );
};

export default DistributionPanelFutures;

const data = (holdings, labels,theme) => ({
  labels,
  datasets: [
    {
      data: holdings,
      backgroundColor: CryptoAssetColors[theme],
    },
  ],
});

const options = (holdings) => {
  const fontColor = "#fff";
  return {
    maintainAspectRatio: true,
    legend: {
      position: "right",
      labels: {
        fontColor,
        padding: 25,
        pointStyle:'circle',
        usePointStyle:true,
        pointStyleWidth:1,
        //align:'left',
        textAlign:'left'
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          var label = holdings[tooltipItem.index] || 0;
          return label + "%";
        },
      },
    },
  };
};
