import React, { useMemo, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import CustomPagination from "../common/CustomPagination";
import "./PaperSpotTab.scss";
import useInput from "../hooks/useInput";
import usePagination from "../hooks/usePagination";
import DefaultPagination from "../common/DefaultPagination";
import CustomCheckbox from "../bots/new-bot/modules/CustomCheckbox";
import { useTranslation } from "react-i18next";

const PaperIsolatedTab = (props) => {
  const { totalIsolated, totalEquityIsolated, totalDebtIsolated } = props;
  const {t} = useTranslation()
  const pairs = props.pairs || [];
  const [rangeItems, bindPagination] = usePagination(0, 5);
  const [hideSmall, setHideSmall] = useState(false);
  const [search, bindSearch] = useInput("");
  const filterPairs = useMemo(() => {
    return pairs
      .filter(
        (el) =>
          !search.length || el.name.toLowerCase().includes(search.toLowerCase())
      )
      .filter((el) => {
        if (!hideSmall) return true;
        const { base, quote } = el;
        return base.total != 0 || quote.total != 0;
      });
  }, [search, hideSmall, pairs]);
  const coinItems = filterPairs.slice(...rangeItems).flatMap((el, id) => {
    return [el.base, el.quote].map((coin, index) => (
      <tr key={id + coin.coin} >
        {index === 0 ? (
          <td rowSpan={2} className="text-left pl-4">
            {el.name}
          </td>
        ) : (
          <></>
        )}
        {index === 0 ? <td rowSpan={2}>{el.risk || 0}</td> : <></>}
        <td className="text-left pl-4">
          <img src={coin.image} width={20} />{" "}
          <span className="ml-2">{coin.coin}</span>
        </td>
        <td>{(coin.total || 0).toFixed(6)}</td>
        <td>{(coin.free || 0).toFixed(6)}</td>
        <td>{(coin.used || 0).toFixed(6)}</td>
        <td>{(coin.borrowed || 0).toFixed(6)}</td>
      </tr>
    ));
  });
  return (
    <div className="spot-tab">
      
      <div className="mt-4 bot-tab">
      <div className="" >
            <div className="deals-table">
                <table className="">
                    <tbody>
                        <tr>
                          <td colSpan={5}>
                          <Row noGutters className="pt-3">
                              <Col>
                                <div className="title">Total Balance</div>
                                <div className="value paper-tab-currency">{(totalIsolated || 0).toFixed(6)} BTC</div>
                              </Col>
                              <Col>
                                <div className="title">Total Equity</div>
                                <div className="value paper-tab-currency">{(totalEquityIsolated || 0).toFixed(6)} BTC</div>
                              </Col>
                              <Col>
                                <div className="title">Total Debt</div>
                                <div className="value paper-tab-currency">{(totalDebtIsolated || 0).toFixed(6)} BTC</div>
                              </Col>
                             
                              <Col className="search-box">
                                <Form.Control placeholder="Search Stock" {...bindSearch} className="input-text mb-2"/>
                                <CustomCheckbox 
                                  val={hideSmall} 
                                  checked={hideSmall} 
                                  callback={setHideSmall}
                                  label={t("Hide Small Balance")}
                                  id={'hidesmall'}
                                  >
                              </CustomCheckbox>
                              </Col>
                             </Row>
                          </td>
                        </tr>
                        <tr valign="top">
                        <th >Pair</th>
                        <th>Risk</th>
                        <th>Coin</th>
                        <th>Total</th>
                        <th>Available</th>
                        <th>Reserved</th>
                        <th>Borrowed</th>
                        </tr>
                        
                        {coinItems}
                    </tbody>
                </table>
          </div>
      </div>
    </div>
    <DefaultPagination {...bindPagination} totalCount={filterPairs.length} />
  </div>
    
  );
};

export default PaperIsolatedTab;
