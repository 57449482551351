import React, { useState, useEffect } from "react";
import ContentContainer from "../common/ContentContainer";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import useInput from "../hooks/useInput";
import { connect, useSelector } from "react-redux";
import NoData from "../common/NoData";
import { deletePriceAlert, getPriceAlerts, updatePriceAlert } from "../../actions/price-alert-action";
import PriceAlertCreateModal from "./PriceAlertCreateModal";
import { toast } from "react-toastify";
import PriceAlertEditModal from "./PriceAlertEditModal";
import Loading from "../common/Loading";
import { useTranslation } from "react-i18next";
import FuturesPriceAlertCreateModal from "./FuturesPriceAlertCreateModal";
import { futurePairName } from "../../utils/Utils";


const PriceAlert = (props) => {
const {getPriceAlerts,deletePriceAlert,updatePriceAlert} = props
const isFuture = useSelector((state) => state.settings.isFuture);
const {t} = useTranslation()
const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
const alerts =  useSelector((state) => state.priceAlert.alerts) || [];
const [showCreateAlert,setShowCreateAlert] = useState(false)
const [showEditAlert,setShowEditAlert] = useState(false)
const [editAlert,setEditAlert] = useState({})
const [loading, setLoading] = useState(false);

useEffect(() => {
    getPriceAlerts();
  }, [showCreateAlert,showEditAlert]);
const clickDelete = (id)=>{
    deletePriceAlert(id).then(()=>{
        getPriceAlerts();
        toast.success("Success")
    })
}
const clickEdit = (item)=>{
    setEditAlert(item)
    setShowEditAlert(true)
}
const updateAlert = (item,status)=>{
    setLoading(true)
    let params = {
        id:item.id,
        status:status
    }
    updatePriceAlert(params).then((res)=>{
        setLoading(false)
        toast.success("success")
        getPriceAlerts();
    }).catch((err)=>{
        toast.error(err.message)
        setLoading(false)
    })
}
const alertItems = alerts.map((item,id)=>{
    return (
        <tr key={id}>
            <td className="text-left"><img src={item.exchange.image} width={25}/>{" "}{item.exchange.name}</td>
            <td><img src={item.pair && item.pair.base_image} width={25}></img>{" "}{item.pair && futurePairName(item.pair)}</td>
            <td>{item.condition=="le"?<span className="down">Less than</span>:<span className="up">Greater than</span>}</td>
            <td>{item.price}</td>
            <td>{item.status==1?"Active":"Paused"}</td>
            <td width={180}>
                <div className="d-flex justify-content-around">
                {item.status==1?<i className="fas fa-pause action-icon pointer" onClick={()=>updateAlert(item,0)}></i>:
                <i className="fas fa-play action-icon pointer" onClick={()=>updateAlert(item,1)}></i>
                }
                <i className="far fa-edit action-icon pointer" onClick={()=>clickEdit(item)}></i> 
                <i className="fas fa-trash-alt text-danger pointer" onClick={()=>clickDelete(item.id)}></i>
                </div>
                
            </td>
        </tr>
    )
})
  return (
    
      <ContentContainer url="price-alert" title={t("price-alert")} showExchange={true}>
        <div className="dashboard-content">
          <Row style={{ marginTop: 30 }}>
              <Col >
              <div className="d-flex justify-content-end price-alert-plus">
              <div className="create-bot-icon " >
                <img src="/images/icons/add-bot.svg" onClick={()=>setShowCreateAlert(true)}></img>
              </div>
              </div>
              <Loading show={loading} onHide={() => setLoading(false)}>
              <div className="mt-4 bot-tab">
            <div className="" >
                    <div className="deals-table">
                        <table className="">
                            <tbody>
                                <tr valign="top">
                                    <th className="text-left">{t("exchange")}</th>
                                    <th>{t("pair")}</th>
                                    <th>{t("type")}</th>
                                    <th>{t("value")}</th>
                                    <th>{t("status")}</th>
                                    <th>{t("action")}</th>
                                </tr>
                                {alertItems}
                            </tbody>
                        </table>
                        <NoData count={alertItems.length} />
                </div>
            </div>
            </div>
            </Loading>
            </Col>
          
          </Row>
        </div>
        {showCreateAlert && (isFuture?<FuturesPriceAlertCreateModal onHide={setShowCreateAlert} t={t}></FuturesPriceAlertCreateModal>:
        <PriceAlertCreateModal onHide={setShowCreateAlert} t={t}></PriceAlertCreateModal>) }
        {showEditAlert && 
        <PriceAlertEditModal onHide={setShowEditAlert} editAlert={editAlert} t={t}></PriceAlertEditModal> }
      </ContentContainer>
  );
};

export default connect(null, {getPriceAlerts ,deletePriceAlert,updatePriceAlert })(PriceAlert);
