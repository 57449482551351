import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Modal,
  Alert,
  Badge,
} from "react-bootstrap";
import {
  getExchanges,
  addExchange,
  deleteExchange,
  resetExchange,
} from "../../actions/setting-action";
import { connect, useSelector } from "react-redux";
import "./AddApiPanel.scss";
import { ExchangeStatus, ExchangeStatusName } from "../../Const";
import { capitalize } from "../../utils/Utils";
import { useTranslation } from "react-i18next";
import { isPremium } from "../../utils/SubUtils";
import ViewIpsModal from "./ViewIpsModal";
import LeftRightForm from "../bots/new-bot/modules/LeftRightForm";
import NoData from "../common/NoData";

const labelWidth = 3;
const AddFuturesApiPanel = (props) => {
  const { getExchanges, addExchange, deleteExchange, resetExchange } = props;
  const {t} = useTranslation()

  const detailCols = [3, 7, 2];
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [passphrase, setPassphrase] = useState("");
  const [exchangeId, setExchangeId] = useState(0);
  const [deleteId, setDeleteId] = useState(0);
  const [showIps, setShowIps] = useState(false);
  const [ips, setIps] = useState([]);
  const exchanges = useSelector((state) => state.settings.exchanges).filter((el)=>el.is_future == true) || [];
  const selectedExchange = exchanges.find((el) => el.id == exchangeId) || {};
  const addExchangeSuccess = useSelector(
    (state) => state.settings.addExchangeSuccess
  );
  const addExchangeError = useSelector(
    (state) => state.settings.addExchangeError
  );
  const addExchangeStart = useSelector(
    (state) => state.settings.addExchangeStart
  );
  const deleteExchangeSuccess = useSelector(
    (state) => state.settings.deleteExchangeSuccess
  );
  const deleteExchangeError = useSelector(
    (state) => state.settings.deleteExchangeError
  );

  const connectedExchanges = useMemo(
    () => exchanges.filter((item) => item.is_connected && item.id != 0),
    [exchanges]
  );
  const disconnectedExchanges = useMemo(
    () => exchanges.filter((item) => !item.is_connected && item.id != 0),
    [exchanges]
  );

  useEffect(() => {
    if (disconnectedExchanges.length) {
      setExchangeId(disconnectedExchanges[0].id);
    }
  }, []);

  useEffect(() => {
    getExchanges();
  }, []);

  useEffect(() => {
    resetExchange();
    return () => {
      resetExchange();
    };
  }, []);

  useEffect(() => {
    if (addExchangeSuccess) {
      setApiKey("");
      setApiSecret("");
      setPassphrase("");
    }
  }, [addExchangeSuccess]);

  const clickAdd = () => {
    const params = {
      id: String(exchangeId),
      api_key: apiKey,
      secret: apiSecret,
      account: passphrase,
    };
    addExchange(params);
  };

  const clickDelete = () => {
    deleteExchange(deleteId);
    setDeleteId(0);
  };
  const showIpsModal = (ipList) => {
    setIps(ipList)
    setShowIps(true)
  };
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );

  const connectedItems = connectedExchanges.map((item, index) => {
    return (
      <tr  key={index}>
        <td className="text-left">
          <img src={item.image} height={20} className="mr-2" />
          {item.name}
        </td>
        <td  className="text-truncate">
          {item.id==16?"********************":item.api_key}
        </td>
        <td className="text-left">
          <a
            href="#"
            className="danger"
            onClick={() => {
              setDeleteId(item.id);
            }}
          >
            <i className="far fa-trash-alt fa-lg text-danger"></i>
          </a>
          {(item.ip_list.length > 0 && isPremium(subscription)) && <button className="btn btn-primary view-ip-btn" onClick={() => showIpsModal(item.ip_list)}>View IPs</button> }
        </td>
      </tr>
    );
  });

  const disconnectedItems = disconnectedExchanges.map((item, index) => {
    return (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    );
  });

  const exchangeItems = connectedExchanges.map((el, id) => {
    return (
      <Row key={id} className="my-3">
        <Col sm={labelWidth} className="pl-5">
          <img src={el.image} height={20} className="mr-2" />
          {el.name}
        </Col>
        <Col sm={12 - labelWidth}>
          <Badge
            className="w-75 p-2"
            variant={el.status !== ExchangeStatus.ok ? "danger" : "success"}
          >
            {ExchangeStatusName[el.status]}
          </Badge>
        </Col>
      </Row>
    );
  });

  return (
    <>
      <Row>
      <Col xl={12}>
      <h5>{t("add-new-futures-exch")}</h5>
          <div className="card-white">
          <div className="cw-body addnewexchange-body">
              <div className="round-bg">
              <LeftRightForm
                    left= {t("exchange")}
                    right={<>
                      <Form.Control
                        as="select"
                        onChange={(e) => setExchangeId(Number(e.target.value))}
                        value={exchangeId}
                        className="select-normal small-select"
                      >
                        {disconnectedItems}
                      </Form.Control>
                      </>}
                  ></LeftRightForm>
                  {selectedExchange.id !=16 ? (
                  <>
                   <LeftRightForm
                    left={t("api-key")}
                    right={<>
                      <Form.Control
                        as="input"
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                        className="input-text"
                      />
                      </>}
                  ></LeftRightForm>
                  <LeftRightForm
                    left= {t("api-secret")}
                    right={<>
                      <Form.Control
                        as="input"
                        value={apiSecret}
                        onChange={(e) => setApiSecret(e.target.value)}
                        className="input-text"
                      />
                      </>}
                  ></LeftRightForm> </>):(<></>)}
                  {selectedExchange.require_param ? (
                   <LeftRightForm
                   left= {<> <Form.Label>
                   {capitalize(selectedExchange.require_param || "")}
                 </Form.Label></>}
                   right={<>
                     <Form.Control
                        as="input"
                        value={passphrase}
                        onChange={(e) => setPassphrase(e.target.value)}
                        className="input-text"
                      />
                     </>}
                 ></LeftRightForm>
                  ) : (
                    <></>
                  )}
                  <Row className="justify-content-center mt-4">
                    <Button onClick={clickAdd} variant="success">
                      {addExchangeStart ? "Updating..." : (selectedExchange.id ==16 ? t("authenticate"):t("add-futures-exchange"))}
                    </Button>
                  </Row>
                  {selectedExchange.id ==16 &&
                  <Row className="justify-content-center mt-2">
                    <p>{t("tradestation")}</p>
                  </Row>}
                  <Row className="justify-content-center mt-2">
                    <Alert
                      show={addExchangeSuccess || addExchangeError != null}
                      variant={addExchangeError ? "danger" : "success"}
                    >
                      {addExchangeError || "Exchange added!"}
                    </Alert>
                  </Row>
                  <div style={{ textAlign: "right", marginTop: 20 }}>
                    <a href={selectedExchange.doc_link} target="_blank">
                      * {t("how-to-connect")} {selectedExchange.name}?
                    </a>
                  </div>
                </div>
              </div>
            </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12}>
          <h5>{t("connected-exgs")}</h5>
            <div className="mt-4 bot-tab">
              <div className="">
                    <div className="deals-table">
                        <table className="">
                            <tbody>
                                <tr valign="top">
                                    <th>{t("exchange")}</th>
                                    <th>{t("api-key")}</th>
                                    <th>{t("action")}</th>
                                </tr>
                                {connectedItems}
                            </tbody>
                        </table>
                        <NoData count={connectedExchanges.length} />
                  </div>
              </div>
            </div>
        </Col>
        
      </Row>
      {/* <div className="round-bg mt-3">
        <h5>{t("exg-status")}</h5>

        {exchangeItems}
      </div> */}

      <Modal show={deleteId > 0} onHide={() => setDeleteId(0)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("warning")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("delete-api-warning")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={clickDelete}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
      <ViewIpsModal show={showIps} onHide={()=>setShowIps(false)} ips={ips}></ViewIpsModal>
    </>
  );
};

export default connect(null, {
  getExchanges,
  addExchange,
  deleteExchange,
  resetExchange,
})(AddFuturesApiPanel);
