import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import PortfolioPanel from "../dashboard/PortfolioPanel";
import DistributionPanel from "./DistributionPanel";
import CoinPanel from "./CoinPanel";
import ContentContainer from "../common/ContentContainer";
import { getPortfolioDetail } from "../../actions/portfolio-action";
import "./Portfolio.scss";
import { useTranslation } from "react-i18next";
import PortfolioOverview from "./PortfolioOverview";

const SpotPortfolio = (props) => {
  const { getPortfolioDetail } = props;
  const {t} = useTranslation()

  const [activeTab, setActiveTab] = useState(0);

  const tabTitles = [t("tb"), t("ab"), t("in-orders")];

  const selectedExchange =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  const coins = useSelector((state) => state.portfolio.coins);

  useEffect(() => {
    getPortfolioDetail(selectedExchange);
  }, [selectedExchange]);

  const totalBalance =
  coins && coins.total &&
    coins.total.reduce((balance, item) => {
      return balance + item.balance_usd;
    }, 0);
  const totalCoins =
  coins && coins.total &&
    coins.total
      .filter((item) => item.balance > 0)
      .map((item) => {
        return {
          name: item.coin_name,
          symbol: item.coin_symbol,
          holdings:
            totalBalance === 0 ? 0 : (item.balance_usd * 100) / totalBalance,
          amount: item.balance,
          total: item.balance_usd.toFixed(2),
          image: item.image,
          change: item.change24h,
          price: item.price_usd,
        };
      });

  const availableCoins =
  coins && coins.available &&
  coins && coins.available
      .filter((item) => item.balance > 0)
      .map((item) => {
        return {
          name: item.coin_name,
          symbol: item.coin_symbol,
          holdings: "",
          amount: item.balance,
          total: item.balance_usd.toFixed(2),
          image: item.image,
          change: item.change24h,
          price: item.price_usd,
        };
      });

  const inOrdersCoins =
  coins && coins.in_orders &&
  coins && coins.in_orders
      .filter((item) => item.balance > 0)
      .map((item) => {
        return {
          name: item.coin_name,
          symbol: item.coin_symbol,
          holdings: "",
          amount: item.balance,
          total: item.balance_usd.toFixed(2),
          image: item.image,
          change: item.change24h,
          price: item.price_usd,
        };
      });

  const tabHeaders = tabTitles.map((item, index) => {
    return (
        <a
          onClick={() => setActiveTab(index)}
          className={index == activeTab ? "list-tab tab-active" : "list-tab tab-inactive"}
          key={index}
        >
          {item}
        </a>
    );
  });

  const coinTabs = [
    <CoinPanel coins={totalCoins} isTotal={true} t={t}/>,
    <CoinPanel coins={availableCoins} isTotal={false} t={t}/>,
    <CoinPanel coins={inOrdersCoins} isTotal={false} t={t}/>,
  ];

  return (
    <ContentContainer url="portfolio" title={t("portfolio")} showExchange={true}>
      <div className="dashboard-content">
        <PortfolioOverview t={t} coins={totalCoins}></PortfolioOverview>
        <div className="d-flex justify-content-between">
            <div className="bot-tabs">
            {tabHeaders}
            </div>
           
        </div>
        <div className="mt-4">
          {coinTabs[activeTab]}
        </div>
      </div>
    </ContentContainer>
  );
};

export default connect(null, { getPortfolioDetail })(SpotPortfolio);
