import React from "react";
import { Form, Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import {  CHANGE_TAKE_PROFIT, ORDER_LAYER_CHANGED } from "../../../../actions/create-bot-type";
import { restrictAlphabets } from "../../../../utils/Utils";
import TutorialPopup from "../../../help/TutorialPopup";
import BooleanView from "../BooleanView";
import LeftRightForm from "../modules/LeftRightForm";

const InterArbitrageEntryView = (props) => {
  const { newBot, isEntry,t } = props;
  const dispatch = useDispatch();
 
  const minimumProfit = (val) => {
    dispatch({
      type: CHANGE_TAKE_PROFIT,
      payload: val,
    });
  };
  const orderLayerChanged = (val) => {
    dispatch({
      type: ORDER_LAYER_CHANGED,
      payload: val,
    });
  };
  return (
    <>
      

      
      <LeftRightForm
            left={<><label>{t("min-profit")}</label></>}
            right={<><label data-text={'%'} className="input-gp-label">
            <Form.Control
                as="input"
                type="number"
                value={newBot.takeProfit}
                onChange={(e) => {
                  minimumProfit(e.target.value);
              }}
                onKeyPress={(e) => {
                  restrictAlphabets(e);
                }}
                className="input-text input-gp-text"
              ></Form.Control>
          </label>
            
          </>}
        ></LeftRightForm>
      <BooleanView
        title={t("order-layers")}
        firstButton="0"
        secondButton="1"
        label1="FIRST LAYER ONLY"
        label2="MULTI"
        selected={newBot.orderLayer}
        onSelect={orderLayerChanged}
        tutorial={<TutorialPopup content={t("order-layer-tutorial")} />}
      />
             
    </>
  );
};

export default connect(null)(InterArbitrageEntryView);
