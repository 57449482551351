import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { SET_BOT_NAME } from "../../../actions/create-bot-type";
import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import { updateBot } from "../../../actions/create-bot-action";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Tab3 = (props)=>{
    const {next,prev,updateBot} = props;
    const {t} = useTranslation()
    const newBot = useSelector((state) => state.createBot.newBot);
    const [setLoading, bindLoading] = useLoading(false);
    const dispatch = useDispatch()
    const setBotName = (val) => {
        dispatch({
            type: SET_BOT_NAME,
            payload: val,
        });
    };
    const clickCreateBot = () => {
        setLoading(true);
        updateBot()
            .then(() => {
            setLoading(false);
            next()
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t(err));
            });
    };
    return(<>
       <div className="tab qs-tab-div">
            <Row className="qs-tab-des-div">
                <Col className="text-left">
                    <h3>Step 3: Creating Your First Bot</h3>
                    <br/>
                    <p>For your first bot, we will automatically set the following parameters:</p>
                    <ul className="para">
                        <li><span className="grey-text">Trading Symbol:</span> {newBot && newBot.pair && newBot.pair.from +'/'+ newBot.pair.to }</li>
                        <li><span className="grey-text">Amount allocated:</span> 1000 USDT</li>
                        <li><span className="grey-text">Paper exchange:</span> risk free and uses fake funds</li>
                    </ul>
                    <br/>
                    <p>This bot will aim to buy low and sell high for {newBot && newBot.pair && newBot.pair.from +'/'+ newBot.pair.to }.</p>
                    <br/>
                    <p>Now, let’s give your first bot a name!</p>
                    <br/>
                    <input type="text" placeholder="GIVE YOUR BOT A NAME" className="form-control input-text qs-bn" value={newBot.name} onChange={(e) => setBotName(e.target.value)}/>
                </Col>
            </Row>
            <Row className="qs-tab-button-div">
                <Col xl={6} className="mt-2">
                    <button type="button" id="nextBtn" className="btn full-btn" onClick={prev}>Previous</button>
                </Col>
                <Col xl={6} className="mt-2">
                    <button type="button" id="prevBtn" className="btn full-btn blue-btn" onClick={()=>clickCreateBot()}>Create my first bot now</button>
                </Col>
            </Row>
            <Loading {...bindLoading} />
        </div>
    </>)
}
export default connect(null,{updateBot}) (Tab3);