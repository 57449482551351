import React, { useState, useMemo, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, Row, Col } from "react-bootstrap";
import BooleanView from "./BooleanView";
import Select from "react-select";
import "./AssetView.scss";
import TutorialPopup from "../../help/TutorialPopup";
import { CHANGE_PAIR, CHANGE_STRATEGY } from "../../../actions/create-bot-type";
import { BotType, StrategyModule } from "../../../Const";
import { getBalance, getPairBalance } from "../../../actions/bot-action";
import { Trans } from "react-i18next";
import CustomRadio from "./modules/CustomRadio";
import LeftRightForm from "./modules/LeftRightForm";

const AssetView = (props) => {
  const { newBot,getBalance,getPairBalance,t,editing } = props;
  const [changeBase, setClickChangeBase] = useState(false);
  const usdPairs = useSelector((state) => state.bots.usdPairs);
  const btcPairs = useSelector((state) => state.bots.btcPairs);
  const ethPairs = useSelector((state) => state.bots.ethPairs);
  const partners = useSelector((state) => state.bots.exchangePartners);
  const intraArbPairs = useSelector((state) => state.bots.intraArbitragePairs);
  const fiat = useSelector((state) => state.bots.fiat) || [];
  const intraBot = newBot.botType === BotType.intra_arbitrage
  const interBot = newBot.botType === BotType.inter_arbitrage
  let coinType = btcPairs && btcPairs.find(o => o.pair_id === newBot.pair.id)?"btc"
  :fiat && fiat.find(o => o.pair_id === newBot.pair.id)?"fiat"
  :usdPairs && usdPairs.find(o => o.pair_id === newBot.pair.id)?"usd"
  :"eth";
  const [baseCoin, setBaseCoin] = useState(editing?coinType:"usd");
  const dispatch = useDispatch();
  const selectedExchange = newBot.exchange;
  const pairList =  useMemo(() => {
    return baseCoin === "usd"
      ? usdPairs
      : baseCoin === "btc"
      ? btcPairs
      : baseCoin === "fiat"
      ?fiat
      :ethPairs;
  }, [baseCoin])

  const pairItems =
    (pairList &&
      pairList.map((item) => ({
        value: item.id,
        label: `${item.pair.from}/${item.pair.to}`,
        from: item.pair.from,
        to: item.pair.to,
      }))) ||
    [];
  const pair = newBot.pair;
  const selectedPair = {
    value: newBot.exchangePairId,
    label: `${pair.from}/${pair.to}`,
    from: pair.from,
    to: pair.to,
  };

  const handleSelectPair = (item) => {
    getBalance(selectedExchange.id)
    const newPair = pairList.find((el) => item.value == el.id) || {};
    getPairBalance(selectedExchange.id,newPair.pair_id,'spot');
    dispatch({
      type: CHANGE_PAIR,
      payload: newPair,
    });
  };
  useEffect(() => {
    getBalance(selectedExchange.id)
    getPairBalance(selectedExchange.id,pair.id,'spot');
  }, [selectedExchange.id]);
  const strategyChanged = (strategy) => {
    dispatch({
      type: CHANGE_STRATEGY,
      payload: strategy,
    });
  };
  useEffect(()=>{
    if(editing)
    {
      if(changeBase){
        dispatch({
          type: CHANGE_PAIR,
          payload: pairList[0],
        });
      }
    }
    else{
      if(pairList[0]){
        dispatch({
          type: CHANGE_PAIR,
          payload: pairList[0],
        });
      }
      
    }
  },[baseCoin])
  const clickChangeBase = (strategy) => {
    setBaseCoin(strategy)
    setClickChangeBase(true)
  };

  return (
    <>
     <Row className="d-flex">
              <Col xl={4} className="cb-form-label">
            
              <Form.Label>
              {t("exchange")} <TutorialPopup content={t("exchange-help")} />
              </Form.Label>
              </Col>
              <Col xl={8}>
              <div className="mt-1 mb-3">
                <img
                  src={selectedExchange.image}
                  width={20}
                  height={20}
                  style={{ marginRight: 10 }}
                />
                <span style={{ fontWeight: "bold", fontSize: 16 }}>
                  {selectedExchange.name}
                </span>
              </div>
              </Col>
            </Row>
            <Row className="d-flex mt-2">
              <Col xl={4} className="cb-form-label">
              <Form.Label className="bot-asset-tour">
                {t("t-pair")} <TutorialPopup content={t("tradingpair-help")} />
              </Form.Label>
              </Col>

              <Col xl={8}>
                
                  <Select
                    value={selectedPair}
                    options={pairItems}
                    onChange={handleSelectPair}
                    className='react-select-container third-step select-normal'
                    classNamePrefix='react-select  select-normal'
                  />
                  <Row>
                <Col  xs={12} lg={8} className="mt-4 d-flex jcb">
                      <CustomRadio
                      type="radio"
                      name="filter"
                      label={t("(S)tablecoin")}
                      val={"usd"}
                      checked={baseCoin==="usd"}
                      callback={() => clickChangeBase("usd")}
                    ></CustomRadio>
                      <CustomRadio
                      type="radio"
                      name="filter"
                      label={t("BTC")}
                      val={"btc"}
                      checked={baseCoin==="btc"}
                      callback={() => clickChangeBase("btc")}
                    ></CustomRadio>
                      <CustomRadio
                      type="radio"
                      name="filter"
                      label={t("ETH")}
                      val={"eth"}
                      checked={baseCoin==="eth"}
                      callback={() => clickChangeBase("eth")}
                    ></CustomRadio>
                      <CustomRadio
                      type="radio"
                      name="filter"
                      label={t("Fiat")}
                      val={"fiat"}
                      checked={baseCoin==="fiat"}
                      callback={() => clickChangeBase("fiat")}
                    ></CustomRadio>
                </Col>
              </Row>
              <Form.Label>
              <Trans i18nKey={'miss-pair-note'}><a className="link" href="mailto:admin@cryptohero.ai"></a></Trans>
            </Form.Label>
            </Col>
          </Row>
              
            
            {(StrategyModule[newBot.botType]) && (
              <div style={{ marginTop: 20 }}>
                <BooleanView
                  title="Strategy"
                  firstButton="Long"
                  secondButton="Short"
                  selected={newBot.strategy}
                  onSelect={strategyChanged}
                  tutorial={<TutorialPopup content={t("strategy-help")} />}
                />
              </div>
            )}
          
    </>
  );
};

export default connect(null,{getBalance,getPairBalance})(AssetView);
