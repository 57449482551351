import React from "react";

const TooltipContent = (props)=>{
    const {content} = props;
    return (
        <div className="tour-tooltip">
            {content}
        </div>
    )
}
export default TooltipContent;