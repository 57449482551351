import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import "./AffiliateNavbar.scss";
import AffiliateRoute from "./AffiliateRoute";
import { signOut } from "../../../actions/auth-action";
import { checkSub } from "../../../utils/SubUtils";
import { Trans, useTranslation } from "react-i18next";

const AffiliateNavbar = (props) => {
  const {t} = useTranslation()
  const { signOut } = props;
  const { pathname } = useLocation();
  const token = useSelector((state) => state.auth.token);
  const name = useSelector((state) => state.auth.user.name) || "";
  const userName = name && name.toUpperCase()
  
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  const { subPremium } = checkSub(subscription);
  const type= subPremium ? "Ambassador" : "Advocates"
  const links = AffiliateRoute.map((route, index) => (
    <Link
      key={index}
      to={route.link}
      className={
        "navlink " + (pathname.includes(route.link) ? "active" : "inactive")
      }
    >
      {t(route.text)}
    </Link>
  ));
  return (
    <>
      <Navbar expand="xl" className="affiliate-navbar">
        <a href="/">
          <div className="row">
            <img src="/images/logo.png" width={60} height={65} />
            <div
              style={{ marginLeft: 10, marginTop: 7 }}
              className="text-right"
            >
              <div
                style={{ fontSize: 25, color: "#002355", fontWeight: "bold" }}
              >
                CryptoHero
              </div>

              <div style={{ fontSize: 13, color: "#4DD0E5" }}>
                {t("affiliate-program")}
              </div>
            </div>
          </div>
        </a>
        <Navbar.Toggle aria-controls="navbarNavDropdown" />
        <Navbar.Collapse id="navbarNavDropdown" className="justify-content-end">
          {links}
          {!token && (
            <>
              <Link to="/login" className="signin">
                {t("login")}
              </Link>
              <Link to="/signup" className="signin">
                {t("sign-up")}
              </Link>
            </>
          )}
          {token && (
            <Button className="signin ml-5" onClick={() => signOut()}>
              {t("logout")}
            </Button>
          )}
        </Navbar.Collapse>
      </Navbar>
      {token && (
        <p className="welcome-msg">
        <Trans i18nKey={"aff-welcome"}>
            <b>{{userName}} {{type}}</b>
        </Trans>
        </p>
      )}
    </>
  );
};

export default connect(null, { signOut })(AffiliateNavbar);
