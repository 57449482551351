import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import { BotType } from "../../../../Const";
import EntryView from "./EntryView";
import ExitView from "./ExitView";
import TutorialPopup from "../../../help/TutorialPopup";

const EntryExitSection = (props)=>{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const newBot = useSelector((state) => state.createBot.newBot);
    const tabTitles = [t("entry-cond"), t("exit-cond")];
    const tourClasses = ["entry-conditions-tour", "exit-conditions-tour"];
    const [activeTab, setActiveTab] = useState(0);
    const [selectedTab, getSelectedTab] = useState('open');
    const changeTab = (index,tab) => {
        setActiveTab(index);
        getSelectedTab(tab)
    };
    const getEntryTitleHelp = ()=>{
        switch (newBot.botType) {
          case BotType.exit:
            return t("entryConditionExitBot-help")
          case BotType.price :
            return t("entryConditionPriceBot-help")
          case BotType.inter_arbitrage :
            return t("arb-entry-help")
          case BotType.intra_arbitrage :
            return t("arb-entry-help")
          default:
            return t("entryCondition-help")
        }
      }
    const tabHeaders = tabTitles.map((item, index) => {
        return (
          
            <a
              onClick={() => changeTab(index,index==0?'open':'close')}
              className={(index == activeTab ? "list-tab tab-active" : "list-tab tab-inactive" )+ " "+ tourClasses[index]}
              key={index}
            >
              {item}{index==0?<TutorialPopup content={getEntryTitleHelp()} />:<></>}
            </a>
          
        );
    });
    const tabs = [
        <EntryView></EntryView>,
        <ExitView></ExitView>
    ];
    return(
        !(newBot.botType === BotType.quick_start) && 
        <>
        <Col xl={6} xs={12} className="asset-param-div">
            <Row>
            <Col xl={12} className="section-tabs-bar">{tabHeaders}</Col>
            </Row>
            <div className="card-white cb-section-1">
                <div className="cw-body">
                    {tabs[activeTab]}
                </div>
            </div>
        </Col> 
        </>
    )
}

export default EntryExitSection;