import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Container,
  Card,
  Form,
  Row,
  Col,
  Button,
  Alert,
} from "react-bootstrap";
import { changePassword, resetUpdatePass } from "../../actions/auth-action";
import "./AccountPanel.scss";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { updateFormula } from "../../actions/setting-action";
import TutorialPopup from "../help/TutorialPopup";
import LeftRightForm from "../bots/new-bot/modules/LeftRightForm";

const labelWidth = 3;

const FormulaPanel = (props) => {
  const { updateFormula} = props;
  const {t} = useTranslation()
  const rfr = useSelector((state) => state.settings.risk_free_rate);
  const updateProfileStart = useSelector((state) => state.settings.updateProfileStart);
  const [newRfr,updateRfr] = useState(rfr)

 
  const clickChangeName = () => {
    updateFormula({
        name:"risk_free_rate",
        value:newRfr
    })
      .then(() => {
        toast.success(t("update-success"))
      })
      .catch((err) => toast.error(err));
  };

  return (
    <>
    <div className="card-white">
        <div className="cw-body settings-body">
      <div className="account-panel round-bg">
      
       
        <LeftRightForm 
        left={<div>{t("rfr")}<TutorialPopup content={t("risk-free-help")} /></div>}
        right={<Form.Control type="text"  value={newRfr} onChange={(e)=>updateRfr(e.target.value)} className="input-text"/>}
        >
        </LeftRightForm>
        <Row className="justify-content-center mt-4">
              <Button onClick={clickChangeName} variant="success">
                {updateProfileStart ? t("updating") : t("update")}
              </Button>
            </Row>
        </div>
        </div>
  </div>
    </>
  );
};

export default connect(null, {updateFormula })(FormulaPanel);
