import React from "react";
import { Col, Row } from "react-bootstrap";

const Tab2 = (props)=>{
    const {next,prev} = props;
    return(<>
        <div className="tab qs-tab-div">
            <Row className="qs-tab-des-div">
                <Col className="text-left">
                    <h2>Step 2: Creating Your First Bot</h2>
                    <br/>
                    <p>For this Quick Start Wizard, we will create a Simple bot for you. The newly created bot will trade on our Paper exchange. This is risk-free and uses our Paper (fake) funds. The aim is to let you have a feel on how CryptoHero monitors and executes trades for you using bots. When you feel confident enough with your bots, you may create your bots to run on your live exchange.</p>
                    <p>What is a Paper Exchange? It is a simulated or virtual exchange that tracks real time movements of cryptos. All bots set up to trade on the Paper exchange do not use real funds. Instead, they use virtual (fake) funds that can be easily adjusted in the Settings page.</p>
                    <br/>
                </Col>
            </Row>
            <Row className="qs-tab-button-div">
                <Col xl={6} className="mt-2">
                    <button type="button" id="prevBtn" className="btn full-btn blue-btn" onClick={prev}>Previous</button>
                </Col>
                <Col xl={6} className="mt-2">
                    <button type="button" id="nextBtn" className="btn full-btn"onClick={next} >Next</button>
                </Col>
            </Row>
          
        </div>
    </>)
}
export default Tab2;