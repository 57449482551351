import React, { useState, useEffect, useMemo } from "react";
import ContentContainer from "../../common/ContentContainer";

import { Row, Col, Card } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../common/CustomPagination";
import usePagination from "../../hooks/usePagination";
import FilterPanel from "./FilterPanel";
import MarketplaceCard from "./MarketplaceCard";
import {SET_DISPLAY_TEMPLATE_IMAGE} from "../../../actions/types"
import { getAvailableBots, getTemplates, getTutorialFlag, hideTutorialFlag } from "../../../actions/marketplace-action";
import { Link } from "react-router-dom";
import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import { isPremium } from "../../../utils/SubUtils";
import NewPagination from "../../common/NewPagination";
import IntroPopup from "../IntroPopup";
import UpgradeModal from "../UpgradeModal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Marketplace = (props) => {
  const {getTemplates,getAvailableBots,getTutorialFlag,hideTutorialFlag} = props;
  const {t} = useTranslation()
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  //const [baseCoin, setBaseCoin] = useState("ALL");
  const [sort, setSort] = useState("apy");
  const [orderBy, setOrderBy] = useState("desc");
  const [strategy, setStrategy] = useState("All");
  const [showAddMarketplaceModal, setShowAddMarketplaceModal] = useState(false)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const pageNum=1;
  const itemsPerPage = 12;
  var templates = useSelector((state) => state.marketplace.templates.data) || [];
  const featuredTemplates = useSelector((state) => state.marketplace.featuredTemplates) || [];
  templates = templates.filter((el)=>{
    return el.is_featured === 0
  })
  const startItem = useSelector((state) => state.marketplace.templates.startItem) || [];
  const totalTemplates = useSelector((state) => state.marketplace.templates.total) || [];
  const owner_templates_total = useSelector((state) => state.marketplace.templates.my_template_count) || 0;
  const show_marketplace_popup = useSelector((state) => state.marketplace.show_marketplace_popup);
  const subscription =
    useSelector((state) => state.auth.user.user_subscription) || {};
  const isPaid = isPremium(subscription);
  //console.log(useSelector((state) => state.marketplace.templates.data));
  const dispatch = useDispatch()
  const [setLoading, bindLoading] = useLoading();
  const closeCreateTemplateModal  = ()=>{
    getTemplates(pageNum,itemsPerPage,true)
    setShowAddMarketplaceModal(false)
    
  }
  const [show,setShow]= useState(true)
  useEffect(()=>{
    //setLoading(true)
    getAvailableBots()
    getTutorialFlag()
    getTemplates(pageNum,itemsPerPage,false,orderBy,sort,strategy).then(()=>{
    }).catch((err) => {
      toast.error(err);
    });
    dispatch({
      type: SET_DISPLAY_TEMPLATE_IMAGE,
      payload: '',
   });
  },[orderBy,sort,strategy]);
  const loadTemplates = (pageNum)=>{
    getTemplates(pageNum,itemsPerPage,false,orderBy,sort,strategy).then(()=>{
      
    }).catch((err) => {
      toast.error(err);
    });
  }
  const [rangeItems, bindPagination] = usePagination(0, 6);
  
  const configItems = (
    <div className="marketplace-container">
      {featuredTemplates.map((template, id) => (
            <Link to={`/marketplace-detail/${template.id}`}><MarketplaceCard template={template} t={t} featured={true} key={id}></MarketplaceCard></Link>
      ))}
      {templates.map((template, id) => (
          <Link to={`/marketplace-detail/${template.id}`}><MarketplaceCard template={template} t={t} key={id}></MarketplaceCard></Link>
      ))}
    </div>
      
    
  );

  return (
    <>
      <ContentContainer url="marketplace" title={t("marketplace")} showExchange={false}>
        <div className="dashboard-content">
          <div className="d-flex justify-content-between flex-wrap">
                {isPaid?(
                  <div className="d-flex justify-content-between mt-20">
                    <Link to="/bots?rental=true" className="no-decoration"> <button className="btn-link mr-2">{t("mr")}</button> </Link>
                    {owner_templates_total > 0 && <Link to="/manage-bot" className="no-decoration"> <button className="btn-link mr-2">{t("manage")}</button> </Link>}
                    <Link to="/marketplace/add-bot"><button className="btn-link mr-2"  disabled={isPaid?false:true}>{t("add-bots")}</button></Link>
                  </div>
                  ):
                  (
                  <div className="pt-10">
                  <Link to="/bots?rental=true" className="no-decoration"> <button className="btn-link mr-2">{t("mr")}</button> </Link>
                  <button className="btn-link mr-2" onClick={()=>setShowUpgradeModal(true)}>{t("manage")}</button>
                  <Link to="/marketplace/add-bot"> <button className="btn-link mr-2" >{t("add-bots")}</button></Link>
                  </div>
                  )}
                  <FilterPanel
                  sort={sort}
                  setSort={setSort}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  strategy={strategy}
                  setStrategy={setStrategy}
                />
          </div>
              <Loading {...bindLoading}>
              {templates && templates.length >0 ?configItems:<><h4 className="mt-5 ml-5">{t("no-temp-available")}</h4></>}
              </Loading>
                {/* <NoData count={configs && configs.length} /> */}
                <div className="marketplace bottom-center" style={{ width: "90%" }}>
                <NewPagination
                  itemsCountPerPage={itemsPerPage}
                  selectedExchangeId={selectedExchangeId}
                  startItem={startItem}
                  //selectedTab={selectedTab}
                  totalCount={totalTemplates}
                  onChange={loadTemplates}
                  itemsPerPage={itemsPerPage}
                />
                </div>
        </div>
       
        {showUpgradeModal && 
        <UpgradeModal 
            onHide={() => {
             setShowUpgradeModal(false);
            }}
        />}
        {show_marketplace_popup==0 && <IntroPopup onHide={hideTutorialFlag}></IntroPopup> }
      </ContentContainer>
    </>
  );
};

export default connect(null, { getTemplates,getAvailableBots,getTutorialFlag,hideTutorialFlag })(Marketplace);
