import React, { useState, useEffect, useMemo } from "react";
import {
  Col,
  DropdownButton,
  Form,
  Modal,
  Row,
  Button,
  Dropdown,
} from "react-bootstrap";
import { AccountName, AccountType } from "../../Const";
import { connect, useSelector } from "react-redux";
import { roundNumber } from "../../utils/Utils";
import { paperTransfer } from "../../actions/setting-action";
import Loading from "../common/Loading";
import useInput from "../hooks/useInput";
import { toast } from "react-toastify";
import "./PaperTransferModal.scss";
import { useTranslation } from "react-i18next";

const Accounts = [AccountName.spot, AccountName.cross, AccountName.isolated];
const Types = [AccountType.spot, AccountType.cross, AccountType.isolated];

const PaperTransferModal = (props) => {
  const { paperTransfer } = props;
  const {t} = useTranslation()
  const { show, onHide } = props;
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(1);
  const [loading, setLoading] = useState(false);

  const [amount, bindAmount, resetAmount] = useInput("0");
  const { coins, crossCoins, pairs } =
    useSelector((state) => state.settings.paperWallet) || {};
  const [selectedPair, setSelectedPair] = useState("");
  const [selectedCoin, setSelectedCoin] = useState("");
  const [coinOptions, setCoinOptions] = useState(coins || []);
  const [pairOptions, setPairOptions] = useState(pairs || []);

  useEffect(() => {
    if (pairs && pairs.length) {
      setPairOptions(pairs);
      setSelectedPair(pairs[0].id);
    }
  }, [pairs]);
  useEffect(() => {
    if (coins && coins.length) {
      setCoinOptions(coins);
      setSelectedCoin(coins[0].coin);
    }
  }, [coins]);

  const coin = useMemo(
    () => coinOptions && coinOptions.find((el) => el.coin === selectedCoin),
    [selectedCoin, coinOptions]
  );
  const pair = useMemo(
    () => pairOptions && pairOptions.find((el) => el.id == selectedPair),
    [selectedPair, pairOptions]
  );

  const hasIsolated = [from, to].includes(2);

  useEffect(() => {
    if (hasIsolated) {
      if (from != 2) {
        const pairCoins = [pair.quote, pair.base].map((el) => el.coin);
        const tempCoins = (from === 0 ? coins : crossCoins).filter((el) =>
          pairCoins.includes(el.coin)
        );
        setCoinOptions(tempCoins);
        tempCoins && tempCoins.length && setSelectedCoin(tempCoins[0].coin);
      } else {
        const tempCoins = [pair.quote, pair.base];
        setCoinOptions(tempCoins);
        tempCoins && tempCoins.length && setSelectedCoin(tempCoins[0].coin);
      }
    } else {
      const tempCoins = from === 0 ? coins : crossCoins;
      setCoinOptions(tempCoins);
      tempCoins && tempCoins.length && setSelectedCoin(tempCoins[0].coin);
    }
  }, [from, to, pair]);

  const clickTransfer = () => {
    if (amount <= 0) return toast.error(t("input-amount-transfer"));
    setLoading(true);
    paperTransfer({
      exchange_id: 0,
      pair_id: pair.id,
      from: Types[from],
      to: Types[to],
      coin: coin.coin,
      amount,
    })
      .then(() => {
        setLoading(false);
        toast.success(t("trans-succ"));
        onHide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const swapAccount = () => {
    const prevFrom = from;
    setFrom(to);
    setTo(prevFrom);
  };

  const accountItems = Accounts.map((item, id) => {
    return (
      <Dropdown.Item key={id} eventKey={id}>
        {item}
      </Dropdown.Item>
    );
  });

  const coinItems =
    coinOptions &&
    coinOptions.map((coin) => {
      return (
        <Dropdown.Item key={coin.coin} eventKey={coin.coin}>
          <img
            src={`/images/coins/${coin.coin}.png`}
            width={20}
            className="mr-2"
          />{" "}
          {coin.coin}
        </Dropdown.Item>
      );
    });

  const pairItems =
    pairOptions &&
    pairOptions.map((pair) => {
      return (
        <Dropdown.Item key={pair.id} eventKey={pair.id}>
          {pair.name}
        </Dropdown.Item>
      );
    });
  return (
    <Loading show={loading} onHide={() => setLoading(false)}>
      <Modal show={show} onHide={onHide} className="paper-transfer">
        <Modal.Header closeButton>
          <Modal.Title>{t("transfer")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row noGutters>
              <Col style={{ maxWidth: "42%" }}>
                <Form.Group>
                  <Form.Label>{t("from")}</Form.Label>
                  <DropdownButton
                    variant="outline-secondary"
                    title={Accounts[from]}
                    onSelect={(e) => setFrom(Number(e))}
                  >
                    {accountItems}
                  </DropdownButton>
                </Form.Group>
              </Col>
              <Col style={{ maxWidth: "16%" }}>
                <div className="w-100 text-center" style={{ paddingTop: 30 }}>
                  <i className="fas fa-exchange-alt" onClick={swapAccount}></i>
                </div>
              </Col>
              <Col style={{ maxWidth: "42%" }}>
                <Form.Group>
                  <Form.Label>{t("to")}</Form.Label>
                  <DropdownButton
                    variant="outline-secondary"
                    title={Accounts[to]}
                    onSelect={(e) => setTo(Number(e))}
                  >
                    {accountItems}
                  </DropdownButton>
                </Form.Group>
              </Col>
            </Row>
            {hasIsolated && (
              <Form.Group className="my-3">
                <Form.Label>{t("pair")}</Form.Label>
                <DropdownButton
                  variant="outline-secondary"
                  title={pair.name || ""}
                  onSelect={(val) => setSelectedPair(val)}
                >
                  {pairItems}
                </DropdownButton>
              </Form.Group>
            )}

            <Form.Group className="my-3">
              <Form.Label>Coin</Form.Label>
              <DropdownButton
                variant="outline-secondary"
                title={selectedCoin || ""}
                onSelect={(val) => setSelectedCoin(val)}
              >
                {coinItems}
              </DropdownButton>
            </Form.Group>
            <Form.Group className="my-3">
              <Form.Label>{t("amount")}</Form.Label>
              <Form.Control type="number" {...bindAmount} />
            </Form.Group>
            <p className="text-right">
            {t("available")}: {roundNumber(coin && coin.free)} {selectedCoin}
            </p>
            <div>
              <Button
                variant="success"
                className="w-100 mb-3"
                onClick={clickTransfer}
              >
                CONFIRM
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Loading>
  );
};

export default connect(null, { paperTransfer })(PaperTransferModal);
