import React from "react";
import { Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { CLOSE_TOUR_SIMPLE_BOT_WINDOW } from "../../../actions/types";
import "./DashboardInfo.scss"

const CreateBotSubmitInfo = (props)=>{
    const {nextStep,endTakeTour,t,joyRideStep} = props
    const dispatch = useDispatch();
    const clickNextStep = (val)=>{
        dispatch({
            type:CLOSE_TOUR_SIMPLE_BOT_WINDOW,
            payload:true
        })
        setTimeout(()=>nextStep(val), 10);
    }
    const goBack = (val)=>{
        nextStep(val)
    }
    return (
        <div className="tour-popup-div">
            <h2>{t("create-bot")}</h2>
            <p>{t("submit-bot-p1")} </p>
            <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between" style={{width:"126px"}}>
                    <Button onClick={()=>endTakeTour()} variant="secondary">{t("skip")}</Button>
                    <Button onClick={()=>goBack(joyRideStep-1)} variant="secondary">{t("back")}</Button> 
                </div>
                <div  >
                    <Button onClick={()=>clickNextStep(11)} variant="primary">{t("next")}</Button>
                </div>
            </div>
        </div>
    )
}
export default connect(null,{})(CreateBotSubmitInfo)