import React from "react";
import { Button } from "react-bootstrap";
import "./FreeInfo.scss";

const PremiumPlan = (props) => {
  const { isPremium,t } = props;
  return (
    <>
        <h6 className="text-row">
            <i className="fas fa-check item-check"></i>{" "}
            15 {t("active-bots")}
        </h6>
        <h6 className="text-row">
        <i className="fas fa-check item-check"></i> {t("premium-info-1")}
        </h6>
        <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        5 {t("price-alerts")}
        </h6>
        <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        {t("plus-basic-plan")}
        </h6>
    </>
  );
};

export default PremiumPlan;
