import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { connect, useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row } from "react-bootstrap";
import { CHANGE_BACKTEST_END, CHANGE_BACKTEST_START } from "../../../../actions/create-bot-type";

const CustomBackTest = (props) => {
  const { newBot,t } = props;
  
  const dispatch = useDispatch();
  const changeStartDate = (date) => {
    
    dispatch({
      type: CHANGE_BACKTEST_START,
      payload: date.getTime()
    });
  };
  const changeEndDate = (date) => {
    dispatch({
      type: CHANGE_BACKTEST_END,
      payload: date.getTime()
    });
  };
  
  return (
    <>
    <div className="d-flex">
    <div className="ml-1">
            <label>Start Date:</label>
            <ReactDatePicker  selected={newBot.backTestStart} onChange={(date) => changeStartDate(date)} className="form-control input-text"/>
        </div>
        <div className="ml-1">
            <label>End Date:</label>
            <ReactDatePicker  selected={newBot.backTestEnd} onChange={(date) => changeEndDate(date)} className="form-control input-text" />
        </div>
    </div>
        
   
    </>
  );
};

export default connect(null)(CustomBackTest);