import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { SET_JOYRIDE_STEP, TAKE_TOUR_START } from "../../actions/types";
import { useHistory } from "react-router-dom";
import Joyride, { ACTIONS, EVENTS, LIFECYCLE, STATUS } from 'react-joyride';
import CreateBotInfo from "./popups/CreateBotInfo";
import CreateBotSubmitInfo from "./popups/CreateBotSubmitInfo";
import ToolTipComp from "./ToolTipComp";
import TourPopup from "./popups/TourPopup";
import { TourClassess } from "../../Const";
import { useTranslation } from "react-i18next";

const ProjectTour = (props)=>{
  const startTakeTour = useSelector((state) => state.settings.startTakeTour);
  const joyRideStep = useSelector((state) => state.settings.joyRideStep);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const endTakeTour = ()=>{
    dispatch({
      type:TAKE_TOUR_START,
      payload:false
    })
  }
  const nextStep = (step)=>{
    dispatch({
      type:SET_JOYRIDE_STEP,
      payload:step
    })
  }
  const handleJoyrideCallback = data => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
        // Update state to advance the tour
        //this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
        //console.log("data",data)
        nextStep(index + (action === ACTIONS.PREV ? -1 : 1))
      }
      else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        // Need to set our running state to false, so we can restart if we click start again.
        endTakeTour()
        //this.setState({ run: false });
      }

    }
  //console.log(takeTour)
  //console.log(startTakeTour)
  const tootipData = {
      dashboard:{
          title:t('dashboard'),
          title2:t("what-is-paper-exch"),
          p1:t("exch-dropdown"),
          p2:t("paper-trade"),
          step:1
      },
      portfolio_value:{
          title:t("portf-val"),
          p1:t("portfolio-p1"),
          p2:t("portfolio-p2"),
          step:2,
          routeName:"/bots",
          backRoute:"/dashboard"
      },
      bot_info:{
          title:t('bots'),
          p1:t("bot-p1"),
          step:3,
          backRoute:"/dashboard"
      },
      bot_title_info:{
          title:t("bt"),
          p1:t("bot-title-p1"),
          step:5
      },
      asset_info:{
          title:t("simple-bot-ass-view"),
          p1:t("asset-view-p1"),
          step:6
      },
      trade_params:{
          title:t("t-params"),
          p1:t("tradeparam-p1"),
          p2:t("tradeparam-p2"),
          step:7
      },
      entry_conditions:{
          title:t("entry-cond"),
          p1:t("entry-p1"),
          step:8
      },
      exit_conditions:{
          title:t("exit-cond"),
          p1:t("exit-cond-p1"),
          step:9
      },
      backtest:{
          title:t("backtest-btn"),
          p1:t("backtest-p1"),
          step:10
      },
      active_bots:{
          title:t("active-bots"),
          p1:t("active-bots-p1"),
          step:12
      },
      inactive_bots:{
          title:t("inactive-bots"),
          p1:t("inactive-bots-p1"),
          step:13
      },
      rented_bots:{
          title:t("rent-bots"),
          p1:t("rent-bots-p1"),
          step:14,
          routeName:"/deals",
      },
      deals:{
          title:t("deals"),
          p1:t("deals-p1"),
          step:15,
          backRoute:"/bots"
      },
      open_deals:{
          title:t("open-deals"),
          p1:t("open-deals-p1"),
          step:16
      },
      closed_deals:{
          title:t("c-deals"),
          p1:t("c-deals-p1"),
          step:17
      },
      trade_logs:{
          title:t("trade-log"),
          p1:t("trade-log-p1"),
          step:18,
          routeName:"/portfolio",
      },
      portfolio:{
          title:t("portfolio"),
          p1:t("portfoliopage-p1"),
          step:19,
          backRoute:"/deals"
      },
      marketplace:{
          title:t("marketplace"),
          p1:t("marketplace-p1"),
          step:20
      },
      terminal:{
          title:t("t-terminal"),
          p1:t("t-terminal-p1"),
          step:21
      },
      price_alert:{
          title:t("price-alert"),
          p1:t("price-alert-p1"),
          step:22,
          last:true
      }
  }
   
  const TourSteps = []
  for (const key of Object.keys(TourClassess)) {
    let step = {}
    switch(key){
        case "create_bot_btn":
          step = {
            target: TourClassess[key],
            content: <CreateBotInfo nextStep={nextStep} endTakeTour={endTakeTour} t={t} joyRideStep={joyRideStep}></CreateBotInfo>,
          }
          break;
        case "dashboard":
          step = {
            target: TourClassess[key],
            content: <TourPopup nextStep={nextStep} endTakeTour={endTakeTour}  details={tootipData[key]} t={t} joyRideStep={joyRideStep}></TourPopup>,
          }
          break;
        case "create_bot_submit":
          step = {
            target: TourClassess[key],
            content: <CreateBotSubmitInfo nextStep={nextStep} endTakeTour={endTakeTour} t={t} joyRideStep={joyRideStep}></CreateBotSubmitInfo>,
            disableBeacon:true
          }
          break;
        default:
          step = {
            target: TourClassess[key],
            content: <TourPopup nextStep={nextStep} endTakeTour={endTakeTour}  details={tootipData[key]} t={t} joyRideStep={joyRideStep}></TourPopup>,
            disableBeacon:true
          }
      }
      TourSteps.push(step)
  }
    return (
        <Joyride
        steps={TourSteps}
        run={startTakeTour}
        showSkipButton = {false}
        hideCloseButton={true}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        styles={{
          options: {
            width: 600,
            zIndex: 1000,
          }
        }}
        //continuous={true}
        callback={handleJoyrideCallback}
        stepIndex ={joyRideStep}
        tooltipComponent={ToolTipComp}
        />
    )
}
export default connect(null,{})(ProjectTour)