import React from "react";
import { Button } from "react-bootstrap";
import "./FreeInfo.scss";
import { PLAN_NAME } from "../../Const";

const FreeInfo = (props) => {
  const { isPremium,t ,subTier,
    tier,
    plan,} = props;
  return (
    <div className="round-bg premium-info text-center no-pad">
      <div className="sub-info-div">
        <div className="header-row">
          <h5 className="">
            {PLAN_NAME[tier]}
          </h5>
          <div className="row justify-content-center align-items-center h-100">
            <div className="">
               <span className="plan-price-text">{t("free")}</span>
            </div>
           
          </div>
        </div>
        <div className="sub-features-info">
          <h6 className=" text-row">
            <i className="fas fa-check item-check"></i>{t("free-info-1")}
          </h6>
          <h6 className=" text-row">
            <i className="fas fa-check item-check"></i>{t("r-free-trade")}
          </h6>
          <h6 className=" text-row">
            <i className="fas fa-check item-check"></i>{t("unlimited-exg")}
          </h6>
          <h6 className=" text-row">
            <i className="fas fa-check item-check"></i>{t("un-trading-lim")}
          </h6>
          <h6 className=" text-row">
            <i className="fas fa-check item-check"></i>{t("un-positions")}
          </h6>
          <h6 className=" text-row">
            <i className="fas fa-check item-check"></i>{t("no-sharing")}
          </h6>
        </div>
      </div>
      
      <div className="subscribe-btn-div w-100 mb-4">
          <h6 className="promotion-text">
            <a href="https://www.cryptohero.ai/pricing.html" target="_blank">{t("free-info-5")}</a>
         </h6>
          <Button className="subscribe-btn blue-btn">
            {isPremium ? "INCLUDED" : "CURRENT PLAN"}
          </Button>
        </div>
    </div>
  );
};

export default FreeInfo;
