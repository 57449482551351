import React, { useState, useMemo, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, Row, Col } from "react-bootstrap";
import Select , { components } from "react-select";
import TutorialPopup from "../../../help/TutorialPopup";
import { CHANGE_PAIR, CHANGE_SECOND_EXCHANGE, CHANGE_STRATEGY } from "../../../../actions/create-bot-type";
import { BotType, StrategyModule } from "../../../../Const";
import { getBalance, getPairBalance } from "../../../../actions/bot-action";
import { Trans } from "react-i18next";
import BooleanView from "../BooleanView";
import { CHANGE_DEFAULT_PARTNER_PAIRS } from "../../../../actions/types";
import LeftRightForm from "../modules/LeftRightForm";

const AssetViewInterArbitrage = (props) => {
  const { newBot,getBalance,getPairBalance,t,editing } = props;
  const partners = useSelector((state) => state.bots.exchangePartners);
  const intraArbPairs = useSelector((state) => state.bots.intraArbitragePairs);
  const defaultPartnerPairs = useSelector((state) => state.bots.defaultPartnerPairs);
  const intraBot = newBot.botType === BotType.intra_arbitrage
  const interBot = newBot.botType === BotType.inter_arbitrage
  
  const [baseCoin, setBaseCoin] = useState("usd");
  const dispatch = useDispatch();
  const selectedExchange = newBot.exchange;
  //const pairList = intraBot?intraArbPairs:defaultPartnerPairs;
  const pairList =  useMemo(() => {
    return intraBot?intraArbPairs:defaultPartnerPairs
  }, [newBot.exchange2])
  const pairItems =
    (pairList &&
      pairList.map((item) => ({
        value: item.id,
        label: `${item.pair.from}/${item.pair.to}`,
        from: item.pair.from,
        to: item.pair.to,
      }))) ||
    [];
  const partnerExchanges =
    (partners &&
        partners.map((item) => ({
        value: item.exchange.id,
        label:  <div><img src={item.exchange.image} height="20px" width="20px" style={{marginRight:10}}/>{item.exchange.name} </div>,
        isFuture: item.exchange.is_future,
      }))) ||
    [];
  const partnerExchange = newBot.exchange2?newBot.exchange2:(partners[0]?partners[0].exchange:[]);
  const selectedPartnerExchange = {
    value: partnerExchange.id,
    label:  <div><img src={partnerExchange.image} height="20px" width="20px" style={{marginRight:10}} alt="img"/>{partnerExchange.name} </div>,
    isFuture: partnerExchange.is_future,
  }
  const pair = newBot.pair;
  const selectedPair = {
    value: newBot.exchangePairId,
    label: `${pair.from}/${pair.to}`,
    from: pair.from,
    to: pair.to,
  };

  const handleSelectPair = (item) => {
    getBalance(selectedExchange.id)
    const newPair = pairList.find((el) => item.value == el.id) || {};
    getPairBalance(selectedExchange.id,newPair.pair_id,'spot');
    dispatch({
      type: CHANGE_PAIR,
      payload: newPair,
    });
  };
  const handleSelectExchange = (item) => {
    const newPartner = partners.find((el) => item.value == el.exchange.id) || {};
    dispatch({
      type: CHANGE_SECOND_EXCHANGE,
      payload: newPartner,
    });
    dispatch({
      type: CHANGE_DEFAULT_PARTNER_PAIRS,
      payload: newPartner.shared_pairs,
    });
  };

  const strategyChanged = (strategy) => {
    dispatch({
      type: CHANGE_STRATEGY,
      payload: strategy,
    });
  };
  useEffect(()=>{
    if(editing)
    {
    //   if(changeBase){
    //     dispatch({
    //       type: CHANGE_PAIR,
    //       payload: pairList[0],
    //     });
    //   }
    }
    else{
      if(pairList[0]){
        dispatch({
          type: CHANGE_PAIR,
          payload: pairList[0],
        });
      }
      if(partners[0]){
        dispatch({
          type: CHANGE_SECOND_EXCHANGE,
          payload: partners[0],
        });
      }
      
    }
  },[])
  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">{t('pls-add-second-exchg')}</span> 
      </components.NoOptionsMessage>
    );
  };

  return (
    <>
      
           
            <LeftRightForm
              left={<label>{t("Exchange 1")} <TutorialPopup content={t("exchange-help")} /></label>}
              right={<><img
                src={selectedExchange.image}
                width={20}
                height={20}
                style={{ marginRight: 10 }}
              />
              <span style={{ fontWeight: "bold", fontSize: 16 }}>
                {selectedExchange.name}
              </span></>}
            ></LeftRightForm>
            <LeftRightForm
              left={<label>{t("Exchange 2")} <TutorialPopup content={t("exchange2-help")} /></label>}
              right={<><Select
                value={partners && partners.length > 0 ? selectedPartnerExchange:{}}
                options={partnerExchanges}
                onChange={handleSelectExchange}
                className='react-select-container'
                classNamePrefix='react-select'
                components={{ NoOptionsMessage }}
                styles={{ noOptionsMessage: base => ({ ...base }) }}
              /></>}
            ></LeftRightForm>
            <br></br>
            <LeftRightForm
              left={<label>{t("t-pair")} <TutorialPopup content={t("tradingpair-arb-help")} /></label>}
              right={<><Select
                value={selectedPair}
                options={pairItems}
                onChange={handleSelectPair}
                className='react-select-container third-step '
                classNamePrefix='react-select'
              />
               <Form.Label className="mt-2">
              <Trans i18nKey={'miss-pair-note'}><a className="link" href="mailto:admin@cryptohero.ai"></a></Trans>
            </Form.Label>
              </>}
            ></LeftRightForm>
           
           
            <Form.Label>
              {t('balance-note')}
            </Form.Label>
            {(StrategyModule[newBot.botType]) && (
              <div style={{ marginTop: 20 }}>
                <BooleanView
                  title="Strategy"
                  firstButton="Long"
                  secondButton="Short"
                  selected={newBot.strategy}
                  onSelect={strategyChanged}
                  tutorial={<TutorialPopup content={t("strategy-help")} />}
                />
              </div>
            )}
    </>
  );
};

export default connect(null,{getBalance,getPairBalance})(AssetViewInterArbitrage);
