import React from "react";
import { Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { SET_TOUR_SIMPLE_BOT_WINDOW } from "../../../actions/types";
import "./DashboardInfo.scss"

const CreateBotInfo = (props)=>{
    const {nextStep,endTakeTour,t,joyRideStep} = props
    const dispatch = useDispatch();
    const clickNextStep = (val,routeName)=>{
        dispatch({
            type:SET_TOUR_SIMPLE_BOT_WINDOW,
            payload:true
        })
        setTimeout(()=>nextStep(val), 100);
    }
    const goBack = (val)=>{
        nextStep(val)
    }
    return (
        <div className="tour-popup-div">
            <h2>CREATE BOT Button</h2>
            <p><a href="https://docs.cryptohero.ai/bot-settings/advanced-bots" target="_blank">Advanced,</a> 
            <a href="https://docs.cryptohero.ai/bot-settings/dca-bots" target="_blank">DCA,</a>  
            <a href="https://docs.cryptohero.ai/bot-settings/exit-bot" target="_blank">Exit,</a>  
            <a href="https://docs.cryptohero.ai/bot-settings/price-bot" target="_blank">Price Bot,</a> 
            <a href="https://docs.cryptohero.ai/bot-settings/grid-bot" target="_blank">Grid Bot </a> 
            {" "}or click to proceed with the tutorial on creating a Simple bot.</p>
            <p>{t("create-bot-p2")}</p>
            
            {/* <div className="d-flex justify-content-between">
                <Button onClick={()=>endTakeTour()} variant="secondary">Skip</Button>
                <Button onClick={()=>clickNextStep(4)} variant="primary">Next</Button>
            </div> */}
            <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between" style={{width:"126px"}}>
                    <Button onClick={()=>endTakeTour()} variant="secondary">{t("skip")}</Button>
                    <Button onClick={()=>goBack(joyRideStep-1)} variant="secondary">{t("back")}</Button> 
                </div>
                <div  >
                    <Button onClick={()=>clickNextStep(4)} variant="primary">{t("next")}</Button>
                </div>
            </div>
        </div>
    )
}
export default connect(null,{})(CreateBotInfo)