import React, { useMemo, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import CustomPagination from "../common/CustomPagination";
import "./PaperSpotTab.scss";
import useInput from "../hooks/useInput";
import usePagination from "../hooks/usePagination";
import { roundNumber } from "../../utils/Utils";
import DefaultPagination from "../common/DefaultPagination";
import { useTranslation } from "react-i18next";
import CustomCheckbox from "../bots/new-bot/modules/CustomCheckbox";

const PaperSpotTab = (props) => {
  const {t}= useTranslation()
  const { totalSpot } = props;
  const coins = props.coins || [];
  const [rangeItems, bindPagination] = usePagination(0, 10);
  const [hideSmall, setHideSmall] = useState(false);
  const [search, bindSearch] = useInput("");
  const filterCoins = useMemo(() => {
    return coins
      .filter(
        (el) =>
          !search.length || el.name.toLowerCase().includes(search.toLowerCase())
      )
      .filter((el) => !hideSmall || (hideSmall && el.balance_usd > 10));
  }, [search, hideSmall, coins]);
  const coinItems = filterCoins.slice(...rangeItems).map((el, id) => {
    return (
      <tr key={id} className="theme-border">
        <td className="text-left pl-4">
          <img src={el.image} width={20} />
          <span className="ml-2">{el.name}</span>
        </td>
        <td>{(el.total || 0).toFixed(6)}</td>
        <td>{(el.free || 0).toFixed(6)}</td>
        <td>{(el.used || 0).toFixed(6)}</td>
        <td>{(el.balance_usd || 0).toFixed(6)}</td>
      </tr>
    );
  });
  return (
    <>
    <div className="spot-tab">
      
      <div className="mt-4 bot-tab">
      <div className="" >
            <div className="deals-table">
                <table className="">
                    <tbody>
                        <tr>
                          <td colSpan={5}>
                          <Row noGutters className="pt-3">
                              <Col>
                                <div className="title">Total Balance</div>
                                <div className="value paper-tab-currency">{(totalSpot || 0).toFixed(6)} BTC</div>
                              </Col>
                              <Col className="search-box">
                                <Form.Control placeholder="Search Stock" {...bindSearch} className="input-text mb-2"/>
                                <CustomCheckbox 
                                  val={hideSmall} 
                                  checked={hideSmall} 
                                  callback={setHideSmall}
                                  label={t("Hide Small Balance")}
                                  id={'hidesmall'}
                                  >
                              </CustomCheckbox>
                              </Col>
                             </Row>
                          </td>
                        </tr>
                        <tr valign="top">
                            <th>{t("Coin")}</th>
                            <th>{t("Total Amount")}</th>
                            <th>{t("Available")}</th>
                            <th>{t("Reserved")}</th>
                            <th>{t("USDT Balance")}</th>
                        </tr>
                        
                        {coinItems}
                    </tbody>
                </table>
          </div>
      </div>
    </div>
    <DefaultPagination {...bindPagination} totalCount={filterCoins.length} />
  </div>
    </>
    
    
  );
};

export default PaperSpotTab;
