import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import SpotOverview from "../dashboard/SpotOverview";
import PortfolioChart from "../dashboard/PortfolioChart";
import ChartTimeFrames from "../dashboard/ChartTimeFrames";
import DistributionPanel from "./DistributionPanel";
import FutureOverview from "../dashboard/FutureOverview";
import DistributionPanelFutures from "./DistributionPanelFutures";

const PortfolioOverviewFutures = (props)=>{

    const {t,currency,assets,totalCoinMCoins,totalUsdMCoins} = props;
    const [showGraph, setShowGraph] = useState(true);
    const [timeframe, setTimeframe] = useState("1M");
    const toggleGraph = () => {
        setShowGraph(!showGraph);
      };
    const [futureType, setFutureType] = useState("spot");
    const isFuture = false
    const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;

    return (
        <>
            <div className="chart-container">
                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div className="panel">
                    <div className="panel-heading " role="tab" id="headingOne">
                    <div className="panel-title">
                        <div className="chart-arrow">
                        <a
                        
                            role="button"
                            data-toggle="collapse"
                            data-target="#graphCollapse"
                            aria-expanded="false"
                            aria-controls="graphCollapse"
                            onClick={toggleGraph}
                        ><i className="fas fa-chevron-down"></i></a>
                        </div>
                        <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 v-pad">
                            <p className="small-text">{t("usd-m-balance")}</p>
                            <h2>
                            {assets && assets.usd_m && assets.usd_m.balance_fiat.toFixed(4)} {currency && currency.name}
                            </h2>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 v-pad">
                            <p className="small-text">{t("coin-m-balance")}</p>
                            <h2>
                            {assets && assets.coin_m && assets.coin_m.balance_fiat.toFixed(4)} {currency && currency.name}
                            </h2>
                        </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className={`transition-div ${showGraph ? "show" : ""}`} id="graphCollapse">
                    <div className="panel-body">
                        <div className="row">
                        <div className="col-xl-6 col-lg-6 v-pad text-center">
                        {totalUsdMCoins && totalUsdMCoins.length > 0 && 
                        <DistributionPanelFutures coins={totalUsdMCoins} t={t} heading={t("USD(S)-M Assets")} />
                        }
                        </div>
                        <div className="col-xl-6 col-lg-6 v-pad text-center">
                        {totalCoinMCoins && totalCoinMCoins.length > 0 && 
                            <DistributionPanelFutures coins={totalCoinMCoins} t={t} heading={t("COIN-M Assets")}/>
                        }
                        </div>
                       
                        </div>
                    </div>
                    </div>
                </div>
                </div>
        </>
    );

}
export default connect(null,{})(PortfolioOverviewFutures)