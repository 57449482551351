import React, { useState, useMemo, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
//import "./AssetView.scss";
import TutorialPopup from "../../help/TutorialPopup";
import * as HelpContent from "../../../utils/HelpContent";
import { CHANGE_PAIR, CHANGE_RENT_PAIR } from "../../../actions/create-bot-type";
import { getBalance, getPairBalance } from "../../../actions/bot-action";
import CustomRadio from "../../bots/new-bot/modules/CustomRadio";
import { Trans } from "react-i18next";

const AssetViewRent = (props) => {
  const { rentBot,getBalance ,selectedExchange,t} = props;
  const [changeBase, setClickChangeBase] = useState(false);
  const usdPairs = useSelector((state) => state.bots.usdPairs);
  const btcPairs = useSelector((state) => state.bots.btcPairs);
  const ethPairs = useSelector((state) => state.bots.ethPairs);
  const fiat = useSelector((state) => state.bots.fiat) || [];
  let coinType = btcPairs && btcPairs.find(o => o.pair_id === rentBot.pair.id)?"btc"
  :fiat && fiat.find(o => o.pair_id === rentBot.pair.id)?"fiat"
  :usdPairs && usdPairs.find(o => o.pair_id === rentBot.pair.id)?"usd"
  :"eth";
  const [baseCoin, setBaseCoin] = useState("usd");
  const dispatch = useDispatch();
  //const selectedExchange = rentBot.exchange;
  const pairList =  useMemo(() => {
    return baseCoin === "usd"
      ? usdPairs
      : baseCoin === "btc"
      ? btcPairs
      : baseCoin === "fiat"
      ?fiat
      :ethPairs;
  }, [baseCoin])

  const pairItems =
    (pairList &&
      pairList.map((item) => ({
        value: item.id,
        label: `${item.pair.from}/${item.pair.to}`,
        from: item.pair.from,
        to: item.pair.to,
      }))) ||
    [];
  const pair = rentBot.pair;
  const selectedPair = {
    value: rentBot.exchangePairId,
    label: `${pair.from}/${pair.to}`,
    from: pair.from,
    to: pair.to,
  };

  const handleSelectPair = (item) => {
    getBalance(selectedExchange.id)
    const newPair = pairList.find((el) => item.value == el.id) || {};
    getPairBalance(selectedExchange.id,newPair.pair_id,'spot');
    dispatch({
      type: CHANGE_RENT_PAIR,
      payload: newPair,
    });
  };
  const clickChangeBase = (strategy) => {
    setBaseCoin(strategy)
    setClickChangeBase(true)
  };
  // useEffect(()=>{
  //     if(pairList[0]){
  //       dispatch({
  //         type: CHANGE_RENT_PAIR,
  //         payload: pairList[0],
  //       });
  //     }
  // },[baseCoin])

  useEffect(()=>{
   
    if(changeBase){
      if(pairList[0]){
        dispatch({
          type: CHANGE_RENT_PAIR,
          payload: pairList[0],
        });
      }
    }
    
    
  },[baseCoin])
  


  return (
    <>
      
      <Row className="d-flex">
              <Col xl={4} className="cb-form-label">
            
              <Form.Label>
              {t("exchange")} <TutorialPopup content={t("exchange-help")} />
              </Form.Label>
              </Col>
              <Col xl={8}>
              <div className="mt-1 mb-3">
                <img
                  src={selectedExchange.image}
                  width={20}
                  height={20}
                  style={{ marginRight: 10 }}
                />
                <span style={{ fontWeight: "bold", fontSize: 16 }}>
                  {selectedExchange.name}
                </span>
              </div>
              </Col>
            </Row>
            <Row className="d-flex mt-2">
              <Col xl={4} className="cb-form-label">
              <Form.Label className="bot-asset-tour">
                {t("t-pair")} <TutorialPopup content={t("tradingpair-help")} />
              </Form.Label>
              </Col>

              <Col xl={8}>
                
                  <Select
                    value={selectedPair}
                    options={pairItems}
                    onChange={handleSelectPair}
                    className='react-select-container third-step select-normal'
                    classNamePrefix='react-select  select-normal'
                  />
                  <Row>
                <Col  xs={12} lg={8} className="mt-4 d-flex jcb">
                      <CustomRadio
                      type="radio"
                      name="filter"
                      label={t("(S)tablecoin")}
                      val={"usd"}
                      checked={baseCoin==="usd"}
                      callback={() => clickChangeBase("usd")}
                    ></CustomRadio>
                      <CustomRadio
                      type="radio"
                      name="filter"
                      label={t("BTC")}
                      val={"btc"}
                      checked={baseCoin==="btc"}
                      callback={() => clickChangeBase("btc")}
                    ></CustomRadio>
                      <CustomRadio
                      type="radio"
                      name="filter"
                      label={t("ETH")}
                      val={"eth"}
                      checked={baseCoin==="eth"}
                      callback={() => clickChangeBase("eth")}
                    ></CustomRadio>
                      <CustomRadio
                      type="radio"
                      name="filter"
                      label={t("Fiat")}
                      val={"fiat"}
                      checked={baseCoin==="fiat"}
                      callback={() => clickChangeBase("fiat")}
                    ></CustomRadio>
                </Col>
              </Row>
              <Form.Label>
              <Trans i18nKey={'miss-pair-note'}><a className="link" href="mailto:admin@cryptohero.ai"></a></Trans>
            </Form.Label>
            </Col>
          </Row>
         
    </>
  );
};

export default connect(null,{getBalance})(AssetViewRent);
