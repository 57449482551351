import {
  Button,
  Row,
  Col,
  OverlayTrigger,
} from "react-bootstrap";
import React from "react";
import "./MarketplaceCard.scss";
import { Link, useHistory } from "react-router-dom";
import { getTemplateDetail } from "../../../actions/marketplace-action";
import { connect } from "react-redux";
import { trimString } from "../../../utils/Utils";

const MarketplaceCard = (props) => {
  //console.log(props)
  const {template,getTemplateDetail,t,featured} = props;
  const back_test = template.back_test || {};
  const history = useHistory(); 
  const clickTemplateDetails = (id)=>{
    history.push(`/marketplace-detail/${id}`)
  }
  return (
    <div className="marketplace-card">
       <div className="pos-relative marketplace-card-content">
      {/* {featured && <>
        <i className="fas fa-crown featured-icon"></i>
        <span className="featured-text">Featured</span>
        </> } */}
        <img src={template.image} className="mp-image"></img>
        <div className="marketplace-card-info-container">
          <p className="marketplace-card-title">{trimString(template.title,50) }</p>
          <div className="">
                <div className="w-100 d-flex justify-content-between mt-2">
                  <div className="mp-label">APY</div>
                  <div className="mp-label-value">{template.apy}%</div>
                </div>
                <div className="w-100 d-flex justify-content-between mt-2">
                  <div className="mp-label">{t("w-l-ratio")}</div>
                  <div className="mp-label-value">{template.wl_ratio}%</div>
                </div>
                <div className="w-100 d-flex justify-content-between mt-2">
                  <div className="mp-label"><span className="mp-label-value">US${template.monthly_fee}</span> /{t("month")}</div>
                  <div className="mp-label-value"><span className={template.strategy=="Long"?"long":"short"}>{template.strategy}</span></div>
                </div>
                {/* <div className="w-100 d-flex justify-content-between mt-2 mb-2">
                  <div className="mp-label">{t("Current Renters")}</div>
                  <div className="mp-label-value">{template.current_renter}</div>
                </div> */}
                <div className="w-100 d-flex justify-content-between mt-4 mb-2">
                  <div className="mp-label"><i class="fas fa-eye"></i>&nbsp;{template.view_count}</div>
                  <div className="mp-label "><i class="fas fa-heart like-icon "></i>&nbsp;{template.like_count}</div>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null,{getTemplateDetail})(MarketplaceCard);
