import React from "react";
import { Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { hideIntroPopup } from "../../../actions/setting-action";
import { CLOSE_TOUR_SIMPLE_BOT_WINDOW, SET_TOUR_SIMPLE_BOT_WINDOW } from "../../../actions/types";
import "./DashboardInfo.scss"

const TourPopup = (props)=>{
    const {nextStep,endTakeTour,details,hideIntroPopup,t,joyRideStep} = props
    const history = useHistory();
    const dispatch = useDispatch();
    const goToNextStep = (val,routeName)=>{
        if(routeName && routeName !== ""){
            history.push(routeName)
        }
        nextStep(val)
    }
    const goBack = (val,routeName)=>{
        if(routeName && routeName !== ""){
            history.push(routeName)
        }
        if(joyRideStep===4){
            dispatch({
                type:CLOSE_TOUR_SIMPLE_BOT_WINDOW,
                payload:true
            })
        }
        if(joyRideStep===11){
            dispatch({
                type:SET_TOUR_SIMPLE_BOT_WINDOW,
                payload:true
            })
            setTimeout(()=>nextStep(val), 100);
        }
        else{
            nextStep(val)
        }
        
    }
    const tourEnded = ()=>{
        hideIntroPopup();
        endTakeTour()
    }
    return (
        <div className="tour-popup-div">
            <h2 className="tour-popup-heading">{details.title}</h2>
            <p>{details.p1} </p>
            {details.title2 && <h2>{details.title2}</h2> }
            {details.p2 && <p>{details.p2} </p> }
            
            <div className="d-flex justify-content-between">
                
                {details.last?
                <Button onClick={()=>tourEnded()} variant="primary">{t("end-tour")}</Button>:
                <>
                <div className="d-flex justify-content-between" style={{width:"126px"}}>
                    <Button onClick={()=>endTakeTour()} variant="secondary">{t("skip")}</Button>
                    {joyRideStep !=0 && <Button onClick={()=>goBack(details.step-2,details.backRoute)} variant="secondary">{t("back")}</Button> }
                </div>
                <div  >
                    <Button onClick={()=>goToNextStep(details.step,details.routeName)} variant="primary">{t("next")}</Button>
                </div>
                </>}
            </div>
        </div>
    )
}
export default connect(null,{hideIntroPopup})(TourPopup)