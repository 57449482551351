import React from "react"
import { connect } from "react-redux"

const HelpDropDown = (props)=>{
    return (
        <>
          <div className="header-menu">
            <div className="dropdown">
              <div className="header-btn help-btn"><img src="/images/icons/icon-question.svg" alt="" className="help-btn-img"/> &nbsp;Help  &nbsp;&nbsp;&nbsp;<i className="fa fa-angle-down"></i></div>
              <div className="dropdown-content">
                <div className="dropdown-triangle"></div>
                <div className="dropdown-list">
                  <a href="mailto:admin@cryptohero.ai" target="_blank"><img src="/img/icon-email.svg" alt=""/> &nbsp;&nbsp;&nbsp;Email</a>
                  <br/>
                  <a href="https://wa.me/message/XNLAUGBUB2IRJ1" target="_blank"><img src="/img/icon-wa.svg" alt=""/> &nbsp;&nbsp;&nbsp;Whatsapp</a>
                  <br/>
                  <a href="https://t.me/novumgroup" target="_blank"><img src="/img/icon-telegram.svg" alt=""/> &nbsp;&nbsp;&nbsp;Telegram</a>
                  <br/>
                  <a href="https://docs.cryptohero.ai/" target="_blank"><img src="/img/icon-userguide.svg" alt=""/> &nbsp;&nbsp;&nbsp;User Guide</a>
                  <br/>
                  <a href="https://docs.cryptohero.ai/faq/frequently-asked-questions" target="_blank"><img src="/img/icon-faqs.svg" alt=""/> &nbsp;&nbsp;&nbsp;FAQ</a>
                  <br/>
                  <a href="https://www.youtube.com/@TradeHeroes_ai/videos" target="_blank"><img src="/img/icon-instagram.svg" alt=""/> &nbsp;&nbsp;&nbsp;Video</a>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </>
    )
}

export default connect(null,{})(HelpDropDown)