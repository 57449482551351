import React from "react";
import { connect, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { BotType } from "../../../../Const";
import NoData from "../../../common/NoData";
import { formatDate, typeNameOrder, orderSide, milisecOf, formatDateTime } from "../../../../utils/Utils";

const Trades = (props) => {
  const { baseCoin, quoteCoin, isLong ,t} = props;
  const backTest = useSelector((state) => state.bots.backTest);
  const theme = useSelector((state) => state.settings.selected_theme);
  const isDca = useSelector((state) => state.createBot.newBot.botType)==BotType.dca;
  const newBot = useSelector((state) => state.createBot.newBot);
  console.log("newBot",newBot)
  const rows =
    backTest &&
    backTest.positions.flatMap((position, index) => {
      //const orders = isDca?position.deal_trade_histories.filter(el => el.name !='TP'):position.deal_trade_histories;
      const orders = position.deal_trade_histories;
      const performance = isDca?position.avg_price:position.performance;
      const futureType = newBot.futureType;
      return orders.map((order, i) => {
        return (
          <tr >
            {i == 0 ? <th rowspan={orders.length}>{index + 1}</th> : <></>}
            <td>{orderSide(order.name, isLong)}</td>
            <td>{typeNameOrder(order.name)}</td>
            <td>{formatDateTime(order.created_at)}</td>
            <td>
              {order.price} {baseCoin}
            </td>
            <td>
              {order.quantity} {((newBot.exchange && newBot.exchange.id==26) && futureType=="usd_m") ?" Contracts": quoteCoin}
            </td>
            {i == 0 ? (
              <td rowSpan={orders.length}>
                {performance} { baseCoin}
              </td>
            ) : (
              <></>
            )}
          </tr>
        );
      });
    });
  return (
    <>
      <div className="mt-4 bot-tab">
        <div className="" >
              <div className="deals-table">
                  <table className="">
                      <tbody>
                          <tr valign="top">
                          <th>#</th>
                          <th>{t("side")}</th>
                          <th>{t("order-type")}</th>
                          <th>{t("date")}</th>
                          <th>{t("price")}</th>
                          <th>{t("amount")}</th>
                          <th>{isDca?"Average Price":"Profit"}</th>
                          </tr>
                          {rows}
                      </tbody>
                  </table>
                  <NoData count={rows && rows.length} />
            </div>
        </div>
      </div>
      
    </>
  );
};

export default connect(null)(Trades);
