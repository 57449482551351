import React, { useState } from "react";
import { Table} from "react-bootstrap";
import { formatDate, futurePairName } from "../../utils/Utils";
import NoData from "../common/NoData";
import "./DealTab.scss";

const DealTradesTab = (props) => {
  const { trades,t} = props;
  
  const tradesItems = trades.map((item, index) => {
    return (
      <tr key={index}>
        <td className="text-left"><img src={item.bot_rent_id?`/images/icons/bots/svg/rent.svg`:`/images/icons/bots/svg/${item.bot_type}.svg`} className="bot-icon-list"></img>{item.bot_name}</td>
        <td>
          {/* <img src={item.exchange_image} height={15} /> */}
          <span className="mx-2">
          {futurePairName(item.pair)}
          {/* {item.base_name}/{item.quote_name} */}
          </span>
        </td>
        <td className={item.side}>
        {item.side}
        </td>
        <td>
        {item.quote_quantity?item.quote_quantity.toFixed(2):0} {item.pair && item.pair.to}
        </td>
        <td>
        {item.filled?item.filled.toFixed(6):0} {item.pair && item.pair.from}
        </td>
        <td>
        {formatDate(item.time,undefined, "DD MMM YYYY HH:mm:ss")}
        </td>
      </tr>
    );
  });
  return (
    <div className="mt-4 bot-tab">
      <div className="" >
            <div className="deals-table">
                <table className="">
                    <tbody>
                      <tr>
                        <th className="text-left">{t("bn")}</th>
                        <th>{t("pair")}</th>
                        <th>{t("side")}</th>
                        <th>{t("volume")}</th>
                        <th>{t("filled")}</th>
                        <th>{t("date")}</th>
                      </tr>
                    </tbody>
                   <tbody>{tradesItems}</tbody>
                </table>
                <NoData count={trades.length} />
            </div>
        </div>
    </div>
  );
};

export default DealTradesTab;


