import React from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTemplateDetail } from "../../../actions/marketplace-action";
import ContentContainer from "../../common/ContentContainer";
import TemplateImage from "./TemplateImage";
import TemplateInfoCard from "./TemplateInfoCard";
import TemplateRentDetails from "./TemplateRentDetails";
import BackButton from "../../common/BackButton";

const BotTemplateDetail = (props)=>{
    const template = useSelector((state)=>state.marketplace.template);
    const {getTemplateDetail} = props;
    let { id } = useParams();
    const {t} = useTranslation()
    useEffect(()=>{
        getTemplateDetail(id)
    },[id])
    return (
        <>
        <ContentContainer url="marketplace" title={t("marketplace")} showExchange={false}>
            <div className="dashboard-content">
            <BackButton></BackButton>
                <div className="template-detail-div mt-5 ml-3">
                    
                    <Row>
                        <Col xl={6} className="mb-4 text-center mpd-left-coloum">
                            <TemplateImage template={template} t={t}/>
                        </Col>
                        <Col xl={6}>
                            <TemplateInfoCard template={template} t={t}/>
                            <TemplateRentDetails template={template} t={t}/>
                        </Col>
                    </Row>
                </div>
            </div>
            
        </ContentContainer>
     </>
    );
}

export default connect(null,{getTemplateDetail})(BotTemplateDetail)