import React from "react";
import { Alert } from "react-bootstrap";
import { BotType, IndicatorType } from "../../../../Const";
import { isLong } from "../../../../utils/Utils";

const TvIndicatorAlertView = (props) => {
  const {
    indicator,editTvAlert,removeTvAlert
  } = props;
  return (
    <Alert variant="success">
      <div className="d-flex justify-content-between">
        <span>
          Trading View Alert {indicator.order} order {indicator.valid_sec?"(Required)":""}
        </span>
        <span>
            <a onClick={() => {
                editTvAlert(indicator);
            }} >
            <i
                className="fas fa-cog"
                style={{ fontSize: 13, marginRight: 20 }}
            ></i>
            </a>
            
          <a
            onClick={() => {
                removeTvAlert(indicator.alert_id);
            }}
          >
            <i className="fas fa-times" style={{ fontSize: 15 }}></i>
          </a>
        </span>
      </div>
    </Alert>
  );
};

export default TvIndicatorAlertView;
