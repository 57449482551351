import React, { useState, useEffect } from "react";
import { Badge, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Switch from "react-switch";
import { connect,useSelector } from "react-redux";
import { activeBot } from "../../actions/bot-action";
import NoData from "../common/NoData";
import "./BotTab.scss";
import { decimalOf, futurePairName, isLong } from "../../utils/Utils";
import { toast } from "react-toastify";
import CreateBotFromRentModal from "../marketplace/bots/CreateBotFromRentModal";
import { t } from "i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const BotTab = (props) => {
  const { bots, onShowBotDetail,startItem,itemsPerPage,pageNum,selectedTab,selectedExchangeId ,onShowBotEdit,t,botAction} = props;
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const [statusList, setStatusList] = useState();
  const sort = useSelector((state) => state.pagination.botSort) || "apy";
  const orderBy = useSelector((state) => state.pagination.botsOrderBy) || "desc";
  //const [startItem, setStartItem] = useState(0);

  useEffect(() => {
    setStatusList(
      bots.reduce((acc, item) => {
        acc[item.id] = item.status === "active";
        return acc;
      }, {})
    );
    //setStartItem(0);
  }, [bots]);
  // const clickEditRentBot = (bot)=>{
  //   setBotSelected(updateEditRentBotConfig(bot))
  //   setShowEditRentBotModal(true)
  // }

  const rows = bots
    //.slice(startItem, startItem + itemsPerPage)
    .map((bot, index) => {
      const pair = bot.exchange_pair.pair;
      const strategy = bot.strategy;
      //const baseCoin = strategy.toLowerCase() == "long" ? pair.to : pair.from;
      const baseCoin = pair.to;

      const statusVariant =
        bot.deal_status === 1
          ? "success"
          : bot.deal_status === 2
          ? "secondary"
          : "danger";
      const clickBotAction = (type) => {
        botAction(bot.id,type);
      };
      return (
        <tr key={bot.id}>
          <td className="">
          <img src={`/images/icons/bots/svg/${bot.type?bot.type:'rent'}.svg`} className="bot-icon-list"></img>
            {bot.name}{bot.notice != "" &&  <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{bot.notice}</Tooltip>}
          ><i className="fa fa-exclamation-circle deativate-warning" aria-hidden="true"></i></OverlayTrigger> }
          </td>
          <td>
            <span className="mr-1">
            <img src={bot.exchange_pair && bot.exchange_pair.pair.base_image} width={20} className="mr-1"></img> {futurePairName(pair)}
              {/* {pair.from}/{pair.to} */}
            </span>
            {bot.strategy === "Both" ?<>
            <span className={"both"}>
              Long/Short
            </span>
            </>:<>
            <span className={isLong(bot.strategy) ? "long" : "short"}>
              {bot.strategy}
            </span>
            </>}
            
            
          </td>
          <td>
            {parseFloat(bot.deals_analysis.total_performance).toFixed(
              decimalOf(baseCoin)
            ) || "0"}{" "}
            {baseCoin}
          </td>
          <td>{parseFloat(bot.deals_analysis.return_value_avg).toFixed(2) || "0"}%</td>
          <td>
          <span className={`w-75 p-1 ${statusVariant}`}>
              {bot.deal_status_name}
            </span>
          </td>
          <td>
            <ul className="action-list">
              <li>
              <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              checked={(statusList && statusList[bot.id]) || false}
              onChange={(checked) => {
                const list = {
                  ...statusList,
                };
                list[bot.id] = checked;
                setStatusList(list);
                props
                  .activeBot(bot.id, checked ? "active" : "inactive",selectedExchangeId,itemsPerPage,pageNum,selectedTab,orderBy,sort)
                  .then(() => {})
                  .catch((err) => {
                    toast.error(err);
                    const list = {
                      ...statusList,
                    };
                    list[bot.id] = !checked;
                    setStatusList(list);
                  });
              }}
              height={20}
              width={40}
              onColor="#05CD99"
              className="mt-2"
            />
              </li>
              <li>
              <a href="#" onClick={!bot.rent_id?()=>clickBotAction('copy'):void(null)} className="action-icon-link" >
                <img src="/images/copy-icon.png" ></img>
              </a>
              </li>
              <li>
              <a href="#" onClick={()=>clickBotAction('edit')} className="action-icon-link">
                <img src="/images/edit-icon.png" ></img>
              </a>
              </li>
              <li>
                <Link to={`/bot-detail/${bot.id}`} className="action-icon-link">
                <img src="/images/open-icon.png" ></img>
                </Link>
              </li>
            </ul>
            
          </td>
         
        </tr>
      );
    });
  return (
    <div className="mt-4 bot-tab">
      <div className="" >
            {/* <h2>Deals in Progress</h2> */}
            <br/>
            <div className="deals-table">
                <table className="">
                    <tbody>
                        <tr valign="top">
                            <th>{t("bn")}</th>
                            <th>{t("pair")}</th>
                            <th>{t("return")}</th>
                            <th>{t("performance")}</th>
                            <th>{t("status")}</th>
                            <th>{t("action")}</th>
                        </tr>
                        {rows}
                    </tbody>
                </table>
            </div>
      </div>
      
    </div>
    
  );
};

export default connect(null, { activeBot })(BotTab);
