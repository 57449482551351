import React from "react";
import TooltipContent from "./TooltipContent";
const ToolTipComp = (props)=>{
   const  { continuous, index, step, backProps, closeProps, primaryProps, tooltipProps } = props;

   return (
       <div {...tooltipProps}>
            <TooltipContent content={step.content}></TooltipContent>
        </div>
);
    
   
}
export default ToolTipComp;