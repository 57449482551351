import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_BOT_NAME } from "../../../../actions/create-bot-type";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import AssetView from "../AssetView";
import GridBotParamsView from "../grid-bot/GridBotParamsView";
import TradeParamsView from "../TradeParamsView";
import { BotType } from "../../../../Const";
import AssetViewIntraArbitrage from "../intra-arbitrage-bot/AssetViewIntraArbitrage";
import AssetViewInterArbitrage from "../inter-arbitrage-bot/AssetViewInterArbitrage";
import AssetViewFutures from "../AssetViewFutures";
import FuturesTradeParamsView from "../FuturesTradeParamsView";

const AssetParamSection = (props)=>{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const newBot = useSelector((state) => state.createBot.newBot);
    const isFuture = useSelector((state) => state.settings.isFuture);
    const tabTitles = [t("asset"), t("t-params")];
    const tourClasses = ["bot-asset-tour", "trade-params-tour"];
    const [activeTab, setActiveTab] = useState(0);
    const [selectedTab, getSelectedTab] = useState('open');
    const changeTab = (index,tab) => {
        setActiveTab(index);
        getSelectedTab(tab)
    };
    const getAssetView = ()=>{
        switch (newBot.botType) {
          case BotType.inter_arbitrage:
            return <AssetViewInterArbitrage newBot={newBot} t={t} editing={newBot.isEditing} />
          case BotType.intra_arbitrage :
            return <AssetViewIntraArbitrage newBot={newBot} t={t} editing={newBot.isEditing}/>
          default:
            return <AssetView newBot={newBot} t={t} editing={newBot.isEditing}/>
        }
      }
    const tabHeaders = tabTitles.map((item, index) => {
        return (
          
            <a
              onClick={() => changeTab(index,index==0?'open':'close')}
              className={(index == activeTab ? "list-tab tab-active" : "list-tab tab-inactive" )+ " "+ tourClasses[index]}
              key={index}
            >
              {item}
            </a>
          
        );
    });
    const tabs = [
        <>{isFuture? <AssetViewFutures newBot={newBot} t={t} editing={newBot.isEditing}/> : getAssetView()}</>,
        <>
        {isFuture?<FuturesTradeParamsView  
                  editing={newBot.isEditing}
                  newBot={newBot}
                  t={t}></FuturesTradeParamsView>:<>
                {newBot.botType === BotType.grid ? (
                  <GridBotParamsView editing={newBot.isEditing}
                  newBot={newBot} t={t}/>
                ) : (
                  <TradeParamsView
                  editing={newBot.isEditing}
                  newBot={newBot}
                  t={t}
                />
                )}
          </>}
        </>

    ];
    return(
        <>
        <Col xl={6} xs={12} className="asset-param-div">
            <Row>
            <Col xl={12} className="section-tabs-bar">{tabHeaders}</Col>
            </Row>
            <div className="card-white cb-section-1">
                <div className="cw-body">
                    <div className={(activeTab==0 ? "tab-content-show" : "tab-content-hide" )}>{tabs[0]}</div>
                    <div className={(activeTab==1 ? "tab-content-show" : "tab-content-hide" )}>{tabs[1]}</div>
                </div>
            </div>
        </Col> 
        </>
    )
}

export default AssetParamSection;