import React, { useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, InputGroup } from "react-bootstrap";

import TutorialPopup from "../../help/TutorialPopup";
import * as HelpContent from "../../../utils/HelpContent";
import { CHANGE_FUND, CHANGE_ORDER_TYPE } from "../../../actions/create-bot-type";
import { isLong } from "../../../utils/Utils";
import { formatNumberToString } from "../../../utils/Utils";
import { BotType, CreateBotModules } from "../../../Const";
import BooleanView from "./BooleanView";
import { FUND_ALLOCATION } from "../../../actions/types";
import FutureNormalParamsView from "./normal-bot/FutureNormalParamsView";
import FuturesSellBotParamsView from "./sell-bot/FuturesSellBotParamsView";
import FuturesPriceBotParamsView from "./price-bot/FuturesPriceBotParamsView";
import FuturesDcaParamsView from "./dca-bot/FuturesDcaParamsView";
import FutureExitParamsView from "./exit-bot/FutureExitParamsView";
import FutureGridBotParamsView from "./grid-bot/FutureGridBotParamsView";

const FuturesTradeParamsView = (props) => {
  const { newBot, editing,t } = props;
  const fund = newBot.initFund;
  const isSellBot = newBot.botType == BotType.sell
  const strategy = isSellBot?"Short":newBot.strategy
  
  const futureType = newBot.futureType;
  const currentCoin = futureType=="usd_m"
    ? newBot.quoteCoin
    : newBot.baseCoin;
  const usdMbalances = useSelector((state) => state.bots.usdMBalances) || [];
  const coinMbalances = useSelector((state) => state.bots.coinMBalances) || [];
  
  const usdMbalance = usdMbalances && usdMbalances.find((item) => item.coin === currentCoin);
  const coinMbalance =
    coinMbalances && coinMbalances.find((item) => item.coin === currentCoin);
  const balance = futureType=="usd_m"?usdMbalance:coinMbalance
  const dispatch = useDispatch();
  const getParamsView = ()=>{
    switch (newBot.botType) {
      case BotType.dca:
        return <FuturesDcaParamsView newBot={newBot} currentCoin={currentCoin} t={t} balance={balance}/>
      case BotType.price :
        return <FuturesPriceBotParamsView newBot={newBot} t={t} balance={balance} currentCoin={currentCoin}/>
      case BotType.sell :
        return <FuturesSellBotParamsView newBot={newBot} t={t} balance={balance} currentCoin={currentCoin}/>
      case BotType.grid :
        return <FutureGridBotParamsView newBot={newBot} t={t} balance={balance} currentCoin={currentCoin} editing={editing}/>
      case BotType.exit :
        return <FutureExitParamsView newBot={newBot} t={t} balance={balance} currentCoin={currentCoin}/>
      default:
        return <FutureNormalParamsView newBot={newBot} t={t} editing={editing} balance={balance}  currentCoin={currentCoin}/>
    }
  }
  // useEffect(() => {
  //   if (balance && !editing) {
  //     fundChanged(balance.free);
  //   }
  // }, [balance]);

  const fundChanged = (val) => {
    dispatch({
      type: CHANGE_FUND,
      payload: val,
    });
  };
  const orderTypeChanged = (orderType) => {
    dispatch({
      type: CHANGE_ORDER_TYPE,
      payload: orderType,
    });
  };

  return (
    <>
     {getParamsView()}
    </>
  );
};

export default connect(null)(FuturesTradeParamsView);
