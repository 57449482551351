import React from "react";
import { Button } from "react-bootstrap";
import "./FreeInfo.scss";

const MasterPlan = (props) => {
  const { isPremium,t } = props;
  return (
    <>
        <h6 className="text-row">
              <i className="fas fa-check item-check"></i>{" "}
              100 {t("active-bots")}
          </h6>
          <h6 className="text-row">
            <i className="fas fa-check item-check"></i>{" "}
            {t("plus-pro-plan")}
          </h6>
    </>
  );
};

export default MasterPlan;
