export const botDetail = "/bot";
export const balanceUrl = "/v2/balances";
export const pairBalanceUrl = "/account";
export const createBotUrl = "/bots";
export const botListUrl = "/bots";
export const updateBotStatusUrl = "/update_bot_status";
export const getBactTestUrl = "/get_back_test_data";
export const deleteBotUrl = "/delete_bot";
export const updateBotUrl = "/bots/";
export const botProfitUrl = "/bot_profit";
export const pairListUrl = "/exchange_pair/list";
export const interArbitragePairsUrl = "/arbitrage/inter";
export const intraArbitragePairsUrl = "/arbitrage/intra";
export const indicatorsUrl = "/indicators";
export const forceOrderUrl = "/place-order";
export const daisyChainUrl = "/daisy_chain";

// Auth
export const login = "/login";
export const signUpUrl = "/register";
export const updatePassUrl = "/update_password";
export const resetPassUrl = "/reset_password";
export const getOtpUrl = "/get_otp";
export const getRegisterOtpUrl = "/register/otp";
export const verifyOtpUrl = "/register/verify_otp";
export const getUserReferralUrl = "/user/referrals";

export const getPortfolioUrl = "/v1/portfolio";
export const portfolioDetail = "/v2/portfolio";
export const getDashboardUrl = "/dashboard";
export const getDailyPnlUrl = "/v2/dashboard/daily_pnl";
export const assetsUrl = "/v2/dashboard/assets";

export const getDealsUrl = "/deals";
export const getDealTradesUrl = "/deals/trades";
export const dealDetailUrl = "/deal";
export const closeDealUrl = "/update_deal_status";
export const deleteDealUrl = "/deal/delete";
export const refreshDealUrl = "/deal/refresh";

export const getDiscoverUrl = "/bot/performance";
export const getTopBotUrl = "/topbots";

// Settings
export const getExchangesUrl = "/get_exchanges";
export const addExchangeUrl = "/add_exchange";
export const deleteExchangeUrl = "/delete_exchange";
export const getPaperSettingUrl = "/get_paper_settings";
export const updatePaperSettingUrl = "/update_paper_settings";
export const updateNotificationUrl = "/update_notification_settings";
export const updateOrderFilledAlertUrl = "/update_order_filled_alert_setting";
export const getSettingsUrl = "/get_settings";
export const getCurrenciesUrl = "/get_currency";
export const updateCurrencyUrl = "/update_currency";
export const getFilterUrl = "/bots/filters";
export const postSubscribe = "/web/subscribe";
export const getSubscription = "/subscribes/load";
export const postCancelSubscription = "/web/subscribe/cancel";
export const postContinueSubsciption = "/web/subscribe/continue";
export const updateCurrentExchange = "/update_current_exchange";
export const updateSettings = "/settings";
export const updateUserRoleUrl = "/user/roles";
export const paypalSubscribeUrl = "/web/subscribe/paypal";
export const cryptoSubscribeUrl = "/subscribe/crypto";
export const getGACodeUrl = "/user/google2fa";
export const checkGACodeUrl = "/user/google2fa/verify";
export const enableGACodeUrl = "/user/google2fa/setting/verify";
export const profileUpdate = "/user/profile_update";

// Terminal
export const placeOrderUrl = "/order/place";
export const accountUrl = "/account";
export const orderListOpenUrl = "/order/list_open";
export const positionsUrl = "/positions";
export const orderHistoryUrl = "/order/history";
export const cancelOrderUrl = "/order/cancel";
export const accountTransferUrl = "/account/transfer";
export const accountRepayUrl = "/account/repay";
export const paperWalletUrl = "/paper_wallet";
export const accountDepositUrl = "/account/deposit";
export const accountWithdrawUrl = "/account/withdraw";
export const getWatchListUrl = "/user/watch_list";
export const updateWatchListUrl = "/user/watch_list";
export const executedBotOrders = "/bot_orders";

export const postContactFormUrl = "/contact";

export const dataExchangesUrl =
  "https://cryptoheroapi.herokuapp.com/api/overall/exchange";
export const dataSymbolUrl =
  "https://cryptoheroapi.herokuapp.com/api/overall/crypto";
export const dataCurrencyUrl =
  "https://cryptoheroapi.herokuapp.com/api/overall/currency";
export const marketplaceTerms =
  "https://cryptohero.ai/bots-marketplace_terms/";
export const botCriteria =
  "https://cryptohero.ai/cryptohero-bots-marketplace-listing-criteria-and-fees/";

export const affiliateSendEmailUrl = "/user/affiliate/friend/send_invitation";
export const affiliateRankUrl = "/user/affiliate/rank";
export const affiliatePointHistoryUrl = "/user/affiliate/point/histories";
export const affiliateSocialPostUrl = "/user/affiliate/posts";
export const affiliateRewardHistoryUrl = "/user/affiliate/referral/histories";
export const affiliateRewardRedeemUrl = "/user/affiliate/redeem";
export const affiliateRewardRedeemHistoryUrl =
  "/user/affiliate/redeem/histories";
export const affiliateFriendDeleteUrl = "/user/affiliate/friend/delete";
export const affiliatePointRedeemUrl = "/user/affiliate/point/redeem";
export const affiliatePointRedeemHistoryUrl =
  "/user/affiliate/point/redeem/histories";
export const promotionCheckUrl = '/user/campaigns/toko';

//marketplace
export const templateSubmitUrl = '/templates'
export const templateUpdateUrl = '/templates/update'
export const getTemplatesUrl = '/templates'
export const templateRentUrl = '/rents'
export const rentDetailUrl = '/rents/'
export const deleteTemplateUrl = '/templates/'
export const createRentBotUrl = '/rents/bots'
export const subscribeBotUrl = '/rents'
export const getMarketplaceSummaryUrl = '/marketplace/summary'
export const eligibleBots = '/templates/candidates'
export const cancelRentalUrl = '/rents/'
export const requestPaymentUrl = '/marketplace/redeems'
export const payoutHistoryUrl = '/marketplace/redeems'
export const marketplaceTutorial = '/marketplace/tutorial'
export const renewRentUrl = '/templates/payments'
export const thumbsUpUrl = '/templates/thumbs-up'

//CJ Tracking
export const cjTrackingUrl = '/cj'

//price alerts
export const priceAlertList = "/alerts"
export const createPriceAlertUrl = "/alerts"
export const updatePriceAlertUrl = "/alerts/"
