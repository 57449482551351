import React, { useState, useMemo, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, Row, Col } from "react-bootstrap";
import BooleanView from "./BooleanView";
import Select from "react-select";
import "./AssetView.scss";
import TutorialPopup from "../../help/TutorialPopup";
import { CHANGE_PAIR, CHANGE_STRATEGY, FUTURE_TYPE } from "../../../actions/create-bot-type";
import { BotType, StrategyModule } from "../../../Const";
import { getBalance, getPairBalance } from "../../../actions/bot-action";
import { Trans } from "react-i18next";
import { futurePairName } from "../../../utils/Utils";
import LeftRightForm from "./modules/LeftRightForm";
import CustomRadio from "./modules/CustomRadio";

const AssetViewFutures = (props) => {
  const { newBot,getBalance,getPairBalance,t,editing } = props;
  const [baseCoin, setBaseCoin] = useState(newBot.pair && newBot.pair.contract_type?newBot.pair.contract_type:"perpetual");
  //const [futureType, setFutureType] = useState("usd_m");
  const coinMDeliver = useSelector((state) => state.bots.coinMDeliver);
  const coinMPerpetual = useSelector((state) => state.bots.coinMPerpetual);
  
  const usdMDeliver = useSelector((state) => state.bots.usdMDeliver);
  const usdMPerpetual = useSelector((state) => state.bots.usdMPerpetual);
  const [changeBase, setClickChangeBase] = useState(false);
  const dispatch = useDispatch();
  const selectedExchange = newBot.exchange;
  const futureType = newBot.futureType;
  const pairList = useMemo(() => {
    return (baseCoin === "deliver" && futureType === "coin_m")
      ? coinMDeliver
      : (baseCoin === "perpetual" && futureType === "coin_m")
      ? coinMPerpetual
      : (baseCoin === "deliver" && futureType === "usd_m")
      ?usdMDeliver:usdMPerpetual;
  }, [baseCoin,futureType]);
  const pairItems =
    (pairList &&
      pairList.map((item) => ({
        value: item.id,
        label: `${futurePairName(item.pair)}`,
        from: item.pair.from,
        to: item.pair.to,
      }))) ||
    [];
  const pair = newBot.pair;
  const selectedPair = {
    value: newBot.exchangePairId,
    label: `${futurePairName(pair)}`,
    from: pair.from,
    to: pair.to,
  };
  useEffect(()=>{
    getBalance(selectedExchange.id)
    if(editing)
    {
      if(changeBase){
        dispatch({
          type: CHANGE_PAIR,
          payload: pairList[0],
        });
      }
    }
    else{
      dispatch({
        type: CHANGE_PAIR,
        payload: pairList[0],
      });
    }
  },[baseCoin,futureType])
  const handleSelectPair = (item) => {
    getBalance(selectedExchange.id)
    const newPair = pairList.find((el) => item.value == el.id) || {};
    getPairBalance(selectedExchange.id,newPair.pair_id,futureType);
    dispatch({
      type: CHANGE_PAIR,
      payload: newPair,
    });
  };

  const strategyChanged = (strategy) => {
    dispatch({
      type: CHANGE_STRATEGY,
      payload: strategy,
    });
  };
  const futureTypeChanged = (value) => {
    setClickChangeBase(true)
    dispatch({
      type: FUTURE_TYPE,
      payload: value,
    });
  };

  return (
    <>
            <LeftRightForm
              left={<> {t("exchange")} <TutorialPopup content={t("exchange-help")} /></>}
              right={<><img
                src={selectedExchange.image}
                width={20}
                height={20}
                style={{ marginRight: 10 }}
              />
              <span style={{ fontWeight: "bold", fontSize: 16 }}>
                {selectedExchange.name}
              </span></>}
            >
            </LeftRightForm>
            <LeftRightForm
              left={<> {t("future-type")} </>}
              right={<>
               <Row>
                <Col  xs={12} lg={12} className="mt-4 d-flex jcb">
                      <CustomRadio
                      type="radio"
                      name="future_type"
                      label={t("USD(S)-M")}
                      val={"usd_m"}
                      checked={futureType==="usd_m"}
                      callback={() => futureTypeChanged("usd_m")}
                    ></CustomRadio>
                      <CustomRadio
                      type="radio"
                      name="future_type"
                      label={t("COIN-M")}
                      val={"coin_m"}
                      checked={futureType==="coin_m"}
                      callback={() => futureTypeChanged("coin_m")}
                    ></CustomRadio>
                    </Col>
                  </Row>
                </>}
            >
            </LeftRightForm>
            <br></br>
            <LeftRightForm
              left={<> {t("t-pair")} <TutorialPopup content={t("tradingpair-help")} /> </>}
              right={<>
              <Select
                    value={selectedPair}
                    options={pairItems}
                    onChange={handleSelectPair}
                    className='react-select-container third-step select-normal'
                    classNamePrefix='react-select select-normal'
                  />
                  <div className="mt-4 d-flex jcb future-type-div">
                  <CustomRadio
                      type="radio"
                      name="filter"
                      label={t("Perpetual")}
                      val={"perpetual"}
                      checked={baseCoin==="perpetual"}
                      callback={() => setBaseCoin("perpetual")}
                    ></CustomRadio>
                      <CustomRadio
                      type="radio"
                      name="filter"
                      label={t("Delivery")}
                      val={"deliver"}
                      checked={baseCoin==="deliver"}
                      callback={() => setBaseCoin("deliver")}
                    ></CustomRadio>
                  </div>
                  <Form.Label>
                    <Trans i18nKey={'miss-pair-note'}><a className="link" href="mailto:admin@cryptohero.ai"></a></Trans>
                  </Form.Label>
                </>}
            >
            </LeftRightForm>
            
            
            
            
            {(StrategyModule[newBot.botType]) && (
              <div style={{ marginTop: 20 }}>
                <BooleanView
                  title="Strategy"
                  firstButton="Long"
                  secondButton="Short"
                  selected={newBot.strategy}
                  onSelect={strategyChanged}
                  tutorial={<TutorialPopup content={t("strategy-help")} />}
                />
              </div>
            )}
          
    </>
  );
};

export default connect(null,{getBalance,getPairBalance})(AssetViewFutures);
