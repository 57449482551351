import React, { useState, useEffect } from "react";

import { Row, Col, Button, DropdownButton, Dropdown } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { getBotProfit } from "../../actions/bot-action";
import { connect, useSelector } from "react-redux";
import { formatDate } from "../../utils/Utils";
import "./ProfitPanel.scss";
import { BotType, BotTypeName,PortfolioColors, SUB_TIER } from "../../Const";
import { checkSub, isPremium } from "../../utils/SubUtils";

const Intervals = ["1D", "3D", "1W", "1M", "3M", "1Y", "ALL"];

const ProfitPanel = (props) => {
  const { exchangeId, getBotProfit, onShowCreateDialog, hideGraph,t } = props;
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const [interval, setInterval] = useState("1M");

  const currency = useSelector((state) => state.settings.currency) || "USD";
  const profit = useSelector((state) => state.bots.profit);
  const subscription =
    useSelector((state) => state.auth.user.user_subscription) || {};
  const { subPremium, subTier } = checkSub(subscription);
  const isPaid = isPremium(subscription);
  const histories = profit && profit.histories;
  const times =
    (histories &&
      histories.map((item) =>
        formatDate(item.date, undefined, "DD MMM HH:mm")
      )) ||
    [];
  const profits = (histories && histories.map((item) => item.profit)) || [];
  const ratios = (histories && histories.map((item) => item.wl_ratio)) || [];
  var isFuture = useSelector((state) => state.settings.isFuture) || false;

  useEffect(() => {
    getBotProfit(interval, exchangeId);
  }, [exchangeId, interval]);

  const intervalList = Intervals.map((item, index) => {
    return (
      <Col key={index}>
        <Button
          key={index}
          className={item === interval ? "button-active" : "button-inactive"}
          onClick={() => setInterval(Intervals[index])}
        >
          {item}
        </Button>
      </Col>
    );
  });

  const botItems = Object.keys(BotType).map((item, id) => {
    const pro = subPremium && subTier === SUB_TIER.pro
    const master = subPremium && subTier === SUB_TIER.master
    const isArbitrage = BotType.inter_arbitrage === BotType[item] || BotType.intra_arbitrage === BotType[item]
    const disabled = BotType.simple !== BotType[item] && !isPaid;
    const disabledItems = (BotType.grid==BotType[item] && !(pro || master)) || (isArbitrage && exchangeId==0) || (isArbitrage && isFuture)
    //const disabledItems = false;
    //const disabled = BotType.simple !== BotType[item] && !isPaid;
    
    return (
      <Dropdown.Item key={id} eventKey={BotType[item]} disabled={disabledItems?true:disabled} className="bot-types">
        <img src={`/images/icons/bots/${item}.png`} className="bot-icon"></img>
        <span className="bot-name">{BotTypeName[item]} {(disabled && (BotType.grid !=BotType[item])) && `(Paid plans)`} {((BotType.grid ==BotType[item]) && !(pro || master) ) && `(Pro Plan)`}</span>
      </Dropdown.Item>
    );
  });

  return (
    <div className="profit-panel">
      <hr />
      <Row noGutters>
        <Col className="dashboard-pnl-nav">
          <Row className="text-title">
            <Col>{t("real-pnl")}</Col>
            <Col>{t("w-l-ratio")}</Col>
            <Col>{t("deals")}</Col>
          </Row>
          <Row className="text-value">
            <Col>
              {profit && profit.current_profit.toFixed(2)}{" "}
              {currency && currency.name}
            </Col>
            <Col>{profit && profit.current_wl_ratio}%</Col>
            <Col>{profit && profit.trade_number}</Col>
          </Row>
        </Col>
        <Col className="text-right create-bot-btn-tour dashboard-create-bot-btn">
          <DropdownButton
            variant="success"
            title={t("new-bot")}
            onSelect={(e) => onShowCreateDialog(e)}
          >
            {botItems}
          </DropdownButton>
        </Col>
      </Row>
      <hr />
      {!hideGraph && (
        <div className="graph-container round-bg">
          <div className="d-flex justify-content-between">
            <Row noGutters className="profit-panel-interval w-50">
              {intervalList}
            </Row>
            <div className="d-flex align-items-center graph-label  profit-panel-labels">
              <span className="profit-label">&#8226;</span>
              <span>{t("profit")}</span>
              <span className="wl-ratio-label">&#8226;</span>
              <span>{t("w-l-ratio")}</span>
            </div>
          </div>
          <div className="d-flex align-items-center graph-label profit-panel-labels-mob">
              <span className="profit-label">&#8226;</span>
              <span>{t("profit")}</span>
              <span className="wl-ratio-label">&#8226;</span>
              <span>{t("w-l-ratio")}</span>
            </div>
          <Row className="mt-3">
            <Line data={dataMix(profits, ratios,theme,t)} options={optionsMix(times)} />
          </Row>
        </div>
      )}
    </div>
  );
};

export default connect(null, { getBotProfit })(ProfitPanel);

const dataMix = (profit, ratio,theme,t) => ({
  datasets: [
    {
      type: "line",
      label: t("profit"),
      data: profit,
      backgroundColor: PortfolioColors[theme][1],
      borderColor: PortfolioColors[theme][1],
      yAxisID: "y-axis-1",
      lineTension: 0.3,
      borderWidth: 2,
    },
    {
      label: t("w-l-ratio"),
      type: "line",
      data: ratio,
      borderColor: PortfolioColors[theme][2],
      backgroundColor: PortfolioColors[theme][2],
      yAxisID: "y-axis-2",
      lineTension: 0.3,
      borderWidth: 2,
    },
  ],
});

const optionsMix = (xLabel, minProfit, minRatio) => {
  const fontSize = 9;
  const fontColor = "#4c556a";
  return {
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: "bottom",
      labels: {
        fontColor,
        boxWidth: 15,
        padding: 25,
      },
    },
    responsive: true,
    tooltips: {
      mode: "label",
    },
    elements: {
      line: {
        fill: false,
      },
      point: {
        radius: 2,
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
          },
          labels: {
            show: true,
          },
          labels: xLabel,
          ticks: {
            fontColor,
            fontSize,
          },
        },
      ],
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          labels: {
            show: true,
          },
          ticks: {
            min: minProfit,
            fontColor,
            fontSize,
          },
          gridLines: {
            display: true,
          },
        },
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-2",
          gridLines: {
            display: false,
          },
          labels: {
            show: true,
          },
          ticks: {
            min: minRatio,
            fontColor,
            fontSize,
          },
        },
      ],
    },
  };
};
