import React, { useState } from "react";
import Header from "./Header";
import { Form, Card, Row, Col, Button } from "react-bootstrap";
import { verifyGaCode } from "../../actions/setting-action";
import { connect, useDispatch } from "react-redux";
import useInput from "../hooks/useInput";
import Loading from "./Loading";
import { toast } from "react-toastify";
import { GA_CONFIRM, SIGN_OUT } from "../../actions/types";
import { useHistory, useLocation } from "react-router";
import HeaderGuest from "./HeaderGuest";

const GAPage = (props) => {
  const { verifyGaCode } = props;
  const [code, bindCode] = useInput("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const pathname = (location.state && location.state.from) || "/dashboard";

  const clickConfirm = (e) => {
    e.preventDefault();
    setLoading(true);
    verifyGaCode(code)
      .then(() => {
        setLoading(false);
        dispatch({
          type: GA_CONFIRM,
        });
        history.push(pathname);
      })
      .catch((err) => {
        toast.error("Code is invalid");
        setLoading(false);
      });
  };

  const signout = () => {
    dispatch({
      type: SIGN_OUT,
    });
  };
  return (
    <Loading show={loading} onHide={() => setLoading(false)}>
      <Header/>
      <Row className="justify-content-center mt-5">
        <Col xs={12} sm={8} md={6} lg={4}>
          <Card>
            <Card.Body>
              <h4 className="text-center">Google Authenticator</h4>
              <p className="text-center mt-5">
                Input Google Authenticator code from your phone
              </p>
              <Form className="text-center">
                <Form.Group>
                  <Form.Control placeholder="Code" {...bindCode} />
                </Form.Group>
                <Button
                  variant="success"
                  className="mt-3 w-50"
                  onClick={clickConfirm}
                >
                  CONFIRM
                </Button>
                <br />
                <br />
                <a href="#" onClick={signout}>
                  Login to another account?
                </a>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Loading>
  );
};

export default connect(null, { verifyGaCode })(GAPage);
