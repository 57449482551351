import React from "react";
import { connect, useSelector } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import DealPanel from "./DealPanel";
import ContentContainer from "../common/ContentContainer";
import "./Dashboard.scss";
import TopTemplatesPanel from "./TopTemplatesPanel";
import NewUserTour from "../tour/NewUserTour";
import { useTranslation } from "react-i18next";
import DashboardOverview from "./DashboardOverview";
import ActiveDeals from "./ActiveDeals";
import TopPerformanceBots from "./TopPerformanceBots";
import WelcomeWizard from "../tour/WelcomeWizard";

const Dashboard = () => {
  const { t } = useTranslation()
  const newUserIntroPupup = useSelector((state) => state.settings.newUserIntroPupup);
  return (
    <ContentContainer url="dashboard" title={t("dashboard")} showExchange={true}>
      <div className="dashboard-content">
        <DashboardOverview t={t}/>
        <div className="row">
          {newUserIntroPupup ? <WelcomeWizard></WelcomeWizard> : <ActiveDeals/>}
          <TopPerformanceBots/>
        </div>
     </div>
    </ContentContainer>
  );
};

export default connect(null)(Dashboard);
