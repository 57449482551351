import { combineReducers } from "redux";
import botReducer from "./bot-reducer";
import authReducer from "./auth-reducer";
import portfolioReducer from "./portfolio-reducer";
import dashboardReducer from "./dashboard-reducer";
import dealReducer from "./deal-reducer";
import discoverReducer from "./discover-reducer";
import settingReducer from "./setting-reducer";
import dataReducer from "./data-reducer";
import { SIGN_OUT } from "../actions/types";
import storage from "redux-persist/lib/storage";
import terminalReducer from "./terminal-reducer";
import affiliateReducer from "./affiliate-reducer";
import createBotReducer from "./create-bot-reducer";
import marketplaceReducer from "./marketplace-reducer";
import createTemplateReducer from "./create-template-reducer";
import SubscriptionReducer from "./subscription-reducer";
import PriceAlertReducer from "./price-alert-reducer";
import paginationReducer from "./pagination-reducer";

const appReducer = combineReducers({
  bots: botReducer,
  auth: authReducer,
  portfolio: portfolioReducer,
  dashboard: dashboardReducer,
  deals: dealReducer,
  pagination: paginationReducer,
  discover: discoverReducer,
  settings: settingReducer,
  terminal: terminalReducer,
  data: dataReducer,
  affiliate: affiliateReducer,
  createBot: createBotReducer,
  marketplace: marketplaceReducer,
  newTemplate: createTemplateReducer,
  subscription: SubscriptionReducer,
  priceAlert: PriceAlertReducer,
});

export default (state, action) => {
  if (action.type == SIGN_OUT) {
    storage.removeItem("persist:root");
    state = undefined;
  }
  return appReducer(state, action);
};
