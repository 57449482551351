import { DASHBOARD_ASSETS, GET_DAILY_PNL, GET_DASHBOARD, GET_TOP_BOTS } from "../actions/types";

const initialState = {
  topBots: [],
  dailyPnl: {},
  assets: {},
};

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DASHBOARD:
      return {
        ...state,
        ...payload,
      };
    case DASHBOARD_ASSETS:
      return {
        ...state,
        assets: payload,
      };
    case GET_TOP_BOTS:
      return {
        ...state,
        topBots: payload,
      };
    case GET_DAILY_PNL:
      return {
        ...state,
        dailyPnl: payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
