import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import { verifyOtp, getRegisterOtp, signOut } from "../../actions/auth-action";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Loading from "../common/Loading";
import useLoading from "../hooks/useLoading";
import useInput from "../hooks/useInput";
import { toast } from "react-toastify";
import * as Analytic from "../../utils/Analytic";
import "./VerifyOtp.scss";
import ContentContainer from "../common/ContentContainer";

const VerifyOtp = (props) => {
  const { verifyOtp, getRegisterOtp, signOut } = props;

  const [code, bindCode] = useInput("");
  const [setLoading, bindLoading] = useLoading();

  const token = useSelector((state) => state.auth.token);

  const history = useHistory();

  if (!token) {
    return <Redirect to="/login" />;
  }

  const clickVerify = (e) => {
    e.preventDefault();
    setLoading(true);
    verifyOtp(code)
      .then(() => {
        setLoading(false);
        Analytic.sendEvent(Analytic.signUpSuccess);
        history.push("/signup/success");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const clickResend = (e) => {
    e.preventDefault();
    setLoading(true);
    getRegisterOtp().then(() => {
      setLoading(false);
      toast.info("New code has been sent.");
    });
  };

  const logout = () => {
    signOut();
  };

  return (
    <>
      <ContentContainer >
      <div className="dashboard-content">
        
        <div className="card-white">
          <div className="cw-body bot-detail">
          <Row className="justify-content-center mt-5 verify-otp">
          <Col className="verify-otp-col">
            <div className="p-4">
              <h6 className="header-text text-center mb-3 top-margin">
                Verify OTP
              </h6>
              <p className="top-margin">
                Check your email inbox to get OTP code (it might be in the spam
                box).
              </p>
              <Form className="mt-3">
                <Form.Group className="top-margin ch-form">
                  <Form.Control placeholder="Code" {...bindCode} autoFocus="true" className="input-text"/>
                </Form.Group>
                <Button
                  type="submit"
                  className="blue-btn w-100"
                  onClick={clickVerify}
                >
                  SUBMIT
                </Button>
                <Row className="justify-content-center w-100 top-margin mt-3">
                  <a className="link pointer" onClick={clickResend}>
                  RESEND OTP
                  </a>
                </Row>
                <Row className="justify-content-center w-100 top-margin">
                  <a href="" onClick={logout}>
                    Use another account?
                  </a>
                </Row>
               
              </Form>
            </div>
          </Col>
          </Row>
          </div>
          </div>
       
        </div>
        <Loading {...bindLoading} />
       
      </ContentContainer>
    </>
  );
};
export default connect(null, { verifyOtp, getRegisterOtp, signOut })(VerifyOtp);
