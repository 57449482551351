import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  getBots,
  getIndicators,
  getRentalBots,
  getSubscription
} from "../../actions/bot-action";
import { Row, Col, Card, Button } from "react-bootstrap";
import "./Bots.scss";
import BotTab from "./BotTab";
import ProfitPanel from "./ProfitPanel";
import CreateBotModal from "./CreateBotModal";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import BotDetail from "./bot-detail/BotDetail";
import ContentContainer from "../common/ContentContainer";
import CustomPagination from "../common/CustomPagination";
import { getBotDetail } from "../../actions/bot-action";
import useLoading from "../hooks/useLoading";
import Loading from "../common/Loading";
import {
  resetBotConfig,
  updateEditBotConfig,
  updatePresetConfig,
  updateCopyBotConfig,
  updateEditRentBotConfig
} from "../../actions/create-bot-action";
import { BotType, CreateBotModalType } from "../../Const";
import { CHANGE_BOT_TYPE } from "../../actions/create-bot-type";
import { CLOSE_TOUR_SIMPLE_BOT_WINDOW, HIDE_GRAPH, SET_JOYRIDE_STEP, SET_TOUR_SIMPLE_BOT_WINDOW } from "../../actions/types";
import BotTabRent from "./BotTabRent";
import CreateBotFromRentModal from "../marketplace/bots/CreateBotFromRentModal";
import BotFilterPanel from "./BotFilterPanel";
import { useTranslation } from "react-i18next";
import BotPageOverview from "./BotPageOverview";
import BotList from "./BotList";

const Bots = (props) => {
  const {
    getBots,
    getSubscription,
    updateEditBotConfig,
    updateCopyBotConfig,
    updateEditRentBotConfig,
    resetBotConfig,
    updatePresetConfig,
    getIndicators,
    getBotDetail,
    getRentalBots
  } = props;
  const { t } = useTranslation()
  const [sort, setSort] = useState("apy");
  const [orderBy, setOrderBy] = useState("desc");
  const { search } = useLocation();
  const history = useHistory();
  const pageNum=1;
  const tabTitles = [t("active"), t("inactive"),t("rent")];
  const tourClasses = ["active-bots-tour", "inactive-bots-tour","rented-bots-tour"];
  const itemsPerPage = 10;
  const [createBotModalType, setCreateBotModalType] = useState(
    CreateBotModalType.notShow
  );
  const [showEditRentBotModal,setShowEditRentBotModal] = useState(false)
  const [showBotDetail, setShowBotDetail] = useState(-1);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, getSelectedTab] = useState('active');
  const [botId, setBotId] = useState(-1);

  var bots = useSelector((state) => state.bots.bots) || [];
  var rents = useSelector((state) => state.bots.rents) || [];
  const tourSimpleBotWindow = useSelector((state) => state.settings.tourSimpleBotWindow);
  const closeTourSimpleBotWindow = useSelector((state) => state.settings.closeTourSimpleBotWindow);
  const [setLoading, bindLoading] = useLoading(false);

  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  const hideGraph = useSelector((state) => state.bots.hideGraph) || false;
  bots = bots.filter((item) => {
    return selectedExchangeId == item.exchange_id;
  });
  // const activeBots = bots.filter((bot) => bot.status === "active");
  // const inactiveBots = bots.filter((bot) => bot.status === "inactive");
  // const activeBots = bots;
  // const inactiveBots = bots;
  const totalBots = useSelector((state) => state.bots.totalBots) || 0;
  const startItem = useSelector((state) => state.bots.startItem) || 1;
  const totalRentBots = useSelector((state) => state.bots.totalRentBots) || 0;
  const startItemRent = useSelector((state) => state.bots.startItemRent) || 1;

  const query = queryString.parse(search);
  const dispatch = useDispatch();

  const clickShowGraph = () =>
    dispatch({
      type: HIDE_GRAPH,
      payload: false,
    });
  
  const showCreateBotModal = (botType) => {
    setCreateBotModalType(CreateBotModalType.newBot);
    dispatch({
      type: CHANGE_BOT_TYPE,
      payload: botType ?? BotType.simple,
    });
  };
  useEffect(()=>{
    if(tourSimpleBotWindow){
      showCreateBotModal(BotType.simple)
      dispatch({
        type: SET_TOUR_SIMPLE_BOT_WINDOW,
        payload: false,
      });
    }
  },[tourSimpleBotWindow])
  useEffect(()=>{
    if(closeTourSimpleBotWindow){
      setCreateBotModalType(CreateBotModalType.notShow);
      dispatch({
        type: CLOSE_TOUR_SIMPLE_BOT_WINDOW,
        payload: false,
      });
    }
  },[closeTourSimpleBotWindow])
  
  useEffect(() => {
    if (botId != -1) {
      setLoading(true);
      getBotDetail(botId)
        .then(() => {
          updateCopyBotConfig(botId);
          setCreateBotModalType(CreateBotModalType.newBot);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          //toast.error(err);
        });
    }
  }, [botId]);
  useEffect(() => {
    getBots(selectedExchangeId,itemsPerPage,pageNum,selectedTab,orderBy,sort);
    getRentalBots(selectedExchangeId,itemsPerPage,pageNum,selectedTab,orderBy,sort);
    getIndicators();
    resetBotConfig();
  }, [sort,orderBy]);

  useEffect(() => {
    getSubscription();
  }, []);

  const changePage = (selectedExchangeId,itemsPerPage,pageNum,selectedTab)=>{
    getBots(selectedExchangeId,itemsPerPage,pageNum,selectedTab,orderBy,sort);
  }
  const changeRentalPage = (selectedExchangeId,itemsPerPage,pageNum,selectedTab)=>{
    getRentalBots(selectedExchangeId,itemsPerPage,pageNum,selectedTab,orderBy,sort);
  }

  useEffect(() => {
    if (search != "") {
      if (query.edit) {
        //updateEditBotConfig();
        //setCreateBotModalType(CreateBotModalType.editBot);
        setCreateBotModalType(CreateBotModalType.notShow);
        onShowBotEdit(query.edit)
      }
      if (query.rental) {
        setActiveTab(2)
      }
      else if(query.copy) {
        setBotId(query.copy)
      }
      else {
        updatePresetConfig(query);
        setCreateBotModalType(CreateBotModalType.presetConfig);
      }
    } else {
      resetBotConfig();
    }
  }, []);

  const onShowBotDetail = (botId) => {
    setShowBotDetail(botId);
  };
  const onShowBotEdit = (botId) => {
    setLoading(true);
    getBotDetail(botId)
        .then((res) => {
          setLoading(false);
          if(!res.rent_id){
            updateEditBotConfig();
            setCreateBotModalType(CreateBotModalType.editBot);
            history.push(`/bots?edit=${botId}`);
          }
          else{
            updateEditRentBotConfig();
            setShowEditRentBotModal(true);
          }
          
        })
        .catch((err) => {
          setLoading(false);
          //toast.error(err);
        });
  };
  const changeTab = (index,tab) => {
    setActiveTab(index);
    getSelectedTab(tab)
    if(index==2){
      getRentalBots(selectedExchangeId,itemsPerPage,pageNum,tab)
    }
    else{
      getBots(selectedExchangeId,itemsPerPage,pageNum,tab)
    }
    
  };

  return (
    <div>
      <ContentContainer url="bots" title={t("bots")} showExchange={true}>
      <div className="dashboard-content">
        <BotPageOverview t={t}></BotPageOverview>
        <BotList t={t}></BotList>
      </div>
      </ContentContainer>
    </div>
  );
};

export default connect(null, {
  getBots,
  getSubscription,
  updateEditBotConfig,
  updateEditRentBotConfig,
  resetBotConfig,
  updatePresetConfig,
  getIndicators,
  updateCopyBotConfig,
  getBotDetail,
  getRentalBots
})(Bots);
